import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { OnSuccess, showNotification, useNotify, useTranslate } from 'ra-core';
import { useState } from 'react';
import {
  AutocompleteArrayInput,
  BooleanField,
  BooleanInput,
  DateField,
  Edit,
  email,
  Loading,
  maxLength,
  OnFailure,
  regex,
  required,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useQuery,
  useRedirect,
} from 'react-admin';
import { RolesField } from '../../components/fields';
import { isPspAdministrator } from '../../configuration';
import { Constants } from '../../constants';
import { UserRoles } from '../../security/UserRoles';
import { getPermissions } from '../../security/utils';
import { useGAPageViews } from '../../utils';
import EditToolbar from './EditToolbar';

const useStyles = makeStyles(
  (theme) => ({
    noActions: {
      marginTop: 0,
    },
  }),
  { name: 'UserProfile' }
);

const UserProfile: any = ({ edit, setEdit, v2Account = false, ...props }) => {
  const { username } = props;

  const classes = useStyles(props);

  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();

  const [readOnly, setReadOnly] = useState(true);
  const [link, setLink] = useState('');

  const handleOnCancel = () => {
    setReadOnly(true);
    if (setEdit) {
      setEdit(false);
    }
  };

  const handleOnEdit = () => {
    setReadOnly(false);
  };

  const handleClick = async () => {
    const userId = props.props ? props.props.record?.id : username;
    let response = await fetch(`/usermgmt/getresetlink/` + userId, {
      method: 'GET',
    });
    if (response.status === 200) {
      let url = await response.text();
      setLink(url);
      showNotification('Password reset link generated', 'info');
    } else {
      showNotification('Error: Password reset link not generated', 'warning');
    }
  };

  const emailValidate = [required(), email()];
  const nameValidate = [required(), maxLength(50)];
  const phoneValidate = [
    maxLength(30),
    regex(
      /^[+]?[-0-9\s]*[(]?[0-9]{1,4}[)]?[-\s/0-9]*/,
      translate('dxMessages.error_messages.phone_number_invalid')
    ),
  ];

  const onFailure: OnFailure = (error) => {
    // eslint-disable-next-line no-console
    console.error(error);
    notify('dxMessages.myAccount.profile_update_error', 'error');
  };

  const onSuccess: OnSuccess = (response) => {
    setReadOnly(true);
    notify(
      'dxMessages.myAccount.profile_update_success',
      'info',
      undefined,
      undefined,
      50000
    );
    if (setEdit !== undefined) {
      setEdit(false);
    } else {
      redirect('list', props.basePath);
    }
  };

  useGAPageViews();

  // Depending on the user version (2 or 3), the user email email is editable or not.
  let userVersion = '2';
  if (typeof localStorage !== 'undefined') {
    try {
      const username = localStorage.getItem('user');
      if (!!username)
        userVersion =
          localStorage.getItem(`${username}.${Constants.USER_VERSION}`) || '2';
    } catch {}
  }

  const { data, loading, error } = useQuery({
    type: 'getList',
    resource: 'usermgmt/roles',
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'DESC' },
    },
  });

  if (loading) return <Loading />;
  if (error) return null;
  if (!data) return null;

  const permissions = getPermissions();

  const appPermissions = permissions[UserRoles.DXPURCHASE_PRODUCT];

  const isCustomerAdmin =
    appPermissions &&
    appPermissions.find((p) => p === UserRoles.CUSTOMER_ADMINISTRATOR);
  const isAdmin = isCustomerAdmin || isPspAdministrator(permissions);
  const isPspAdmin = isPspAdministrator(permissions);
  const usersData = data.filter(
    (d) => d.id.toLowerCase() !== 'DxAdmin'.toLowerCase()
  );

  const CustomEdit = (
    <Edit
      {...props}
      resource={
        v2Account
          ? Constants.RESOURCE_USERPROFILES_V2
          : Constants.RESOURCE_USERPROFILES
      }
      id={props.props ? props.props.record?.id : username}
      undoable={false}
      onFailure={onFailure}
      onSuccess={onSuccess}
      classes={{ noActions: classes.noActions }}
      title={<></>}
    >
      <SimpleForm
        variant='standard'
        toolbar={<EditToolbar onCancel={handleOnCancel} />}
      >
        <TextInput
          source='id'
          validate={nameValidate}
          label='dxMessages.companyManagement.users.userId'
          disabled={true}
        />
        <TextInput
          source='email'
          validate={emailValidate}
          label='dxMessages.myAccount.email'
          disabled={!isPspAdmin}
          //disabled={userVersion === '3'}
        />
        <TextInput
          source='firstName'
          validate={nameValidate}
          label='dxMessages.myAccount.firstName'
        />
        <TextInput
          source='lastName'
          validate={nameValidate}
          label='dxMessages.myAccount.lastName'
        />
        <TextInput
          source='phoneNumber'
          validate={phoneValidate}
          label='dxMessages.myAccount.phoneNumber'
        />
        <BooleanInput
          source='isActiveFlag'
          label='dxMessages.myAccount.enabled'
        />
        {isPspAdmin && v2Account && (
          <button onClick={handleClick}>
            {translate(
              'dxMessages.myAccount.generate_password_reset_link_button'
            )}
          </button>
        )}
        <div>{link}</div>
        {isAdmin && (
          <AutocompleteArrayInput
            style={{ width: '256px' }}
            label='dxMessages.myAccount.roles'
            choices={
              props.resource === Constants.RESOURCE_ACCOUNT ? usersData : data
            }
            source='enabledRoles'
            helperText='dxMessages.companyManagement.users.help'
          />
        )}
        {!isAdmin && <RolesField label='dxMessages.myAccount.roles' />}
      </SimpleForm>
    </Edit>
  );

  if (edit === true) {
    return CustomEdit;
  }

  return (
    <>
      {readOnly ? (
        <Card>
          <Show
            {...props}
            resource={
              v2Account
                ? Constants.RESOURCE_USERPROFILES_V2
                : Constants.RESOURCE_USERPROFILES
            }
            id={props.props ? props.props.record?.id : username}
            component='div'
            classes={{ noActions: classes.noActions }}
            title={<></>}
          >
            <SimpleShowLayout>
              <TextField
                source='id'
                label='dxMessages.companyManagement.users.userId'
              />
              <TextField source='email' label='dxMessages.myAccount.email' />
              <TextField
                source='firstName'
                label='dxMessages.myAccount.firstName'
              />
              <TextField
                source='lastName'
                label='dxMessages.myAccount.lastName'
              />
              <TextField
                source='companyName'
                label='dxMessages.myAccount.companyName'
              />
              <TextField
                source='phoneNumber'
                label='dxMessages.myAccount.phoneNumber'
              />
              <DateField
                label={'dxMessages.companyManagement.audit.tsChange'}
                source='tsChange'
                sortable={false}
                showTime
              />
              <TextField
                label={'dxMessages.companyManagement.audit.userIdChange'}
                source='userIdChange'
                sortable={false}
              />
              <BooleanField
                source='isActiveFlag'
                label='dxMessages.myAccount.enabled'
              />
              {isPspAdministrator(permissions) && (
                <BooleanField source='administratorKz' label='DxAdmin' />
              )}
              <RolesField label='dxMessages.myAccount.roles' />
            </SimpleShowLayout>
          </Show>
          <EditToolbar onEdit={handleOnEdit} readOnly={true} />
        </Card>
      ) : (
        CustomEdit
      )}
    </>
  );
};

export default UserProfile;
