import {
  Constants,
  DocumentTypeCode,
  InvoiceTypeCode,
  Metadata,
  ProcessStatus,
  RegulatorExtraDetailsType,
  Ubl,
} from '@dx-ui/dx-common';
import {
  QuantityType,
  ReceiptLineDetails,
} from '@dx-ui/lib-oasis-ubl-2.1/src/ReceiptAdviceModel';
import type { FormApi } from 'final-form';
import { cloneDeep, first, get, set, unset } from 'lodash';
import { Translate } from 'ra-core';
import { DataProvider } from 'react-admin';
import { UpdateFormTemplateAction } from '../../actions/UpdateFormTemplate';
import {
  CatalogLine,
  CatalogService,
  CompanyAddress,
  CompanyModel,
  CompanyService,
  DataHelpers,
  DocumentServiceFactory,
  FormDataHelpers,
  InvoiceService,
  InvoiceServiceCreator,
  LineProcessor,
  OrderService,
  OrderServiceCreator,
  PeppolProfileDocument,
  PeppolService,
  ReceiptAdviceService,
  ReceiptAdviceServiceCreator,
  ServiceDescriptionCode,
  TaxAndPriceUtils,
  TemplatesService,
  WaybillService,
} from '../../services';
import { InvoiceLine, OnChangeFunctionInfo, P2pData, Template } from '../types';
import { FieldTypes } from './fields/FieldTypes';
import {
  getFieldFromSourceInTemplate,
  getFieldFromTemplate,
  getFieldSourceFromTemplate,
  getOnChangeFunctionFromTemplate,
  getSourceField,
  round,
  setFieldProperty,
} from './utils';

// TODO: refactor this file to avoid mixing to many things

/**
 * Generic list of parameters for OnChangeDo functions
 */
export interface OnChangeDoParams {
  /**
   * THE form
   */
  form: FormApi;
  /**
   * Form template
   */
  template: Template;
  /**
   * Form data
   */
  formData: P2pData;
  /**
   * Gets the field source for which the onChange function has been called
   */
  emitter?: string;
  /**
   * New value
   */
  newValue: string | number | null | undefined;
  /**
   * PreviousValue
   */
  previousValue: string | number | null | undefined;
  /**
   * Action to refresh the form template
   */
  updateFormTemplate: (newTemplate: Template) => UpdateFormTemplateAction;
  /**
   * React-Admin dataProvider
   */
  dataProvider: DataProvider;
  /**
   * React-Admin action for notifications
   */
  showNotification: any;
  /**
   * React-Admin Translate
   */
  translate: Translate;

  documentForm?: FormApi | undefined;
}

/**
 * Generic list of parameters for OnChangeDo functions
 */
interface OnChangeDoParamsInfo<OnChangeDoInfoType = any>
  extends OnChangeDoParams {
  /**
   * Additional data from the template
   */
  onChangeDoInfo: OnChangeDoInfoType;
}

const getFormField = (field: string, params: any) => {
  let formField = getFieldSourceFromTemplate(
    params.template,
    field,
    params.getSource
  );
  if (formField === 'Unknown field') {
    // field can be a source of a non-display value.
    formField = field;
  }
  formField = getSourceField(formField);
  return formField;
};

const getEmitterInLinesTable = (
  lineEmitter: string | undefined
): { emitter: string | undefined; lineIndex: number } => {
  const reg = 'lines\\[([0-9]+)\\].(.*)$';
  var regexp = new RegExp(reg);
  const result = regexp.exec(lineEmitter || '');
  let lineIndex: number = 0;
  let emitter: string | undefined;
  if (result) {
    lineIndex = parseInt(result[1]);
    emitter = result[2];
  }
  return { emitter, lineIndex };
};

const fillAnyValue = (
  field: string | undefined,
  value: any,
  params: OnChangeDoParamsInfo
) => {
  if (field === undefined) {
    return;
  }
  const form = params.form;
  const formField = getFormField(field, params);
  form.change(formField, value ?? '');
};

/**
 * Resets a field triggering its validation
 * @param change
 * @param fieldSource
 * @param value
 */
const resetField = async (
  change: (field: string, value: any) => void,
  fieldSource: string,
  value: any
) => {
  if (!value) {
    // Workaround to force the validation of the field in order to remove a possible remaining error message
    // TODO : found a way to trigger that without setting a value.
    await change(fieldSource, '');
    await change(fieldSource, null);
  } else {
    await change(fieldSource, value);
  }
};

/**
 * Sets totals
 */
const calculateTaxesAndTotals = (params: OnChangeDoParamsInfo) => {
  const form = params.form;
  const documentForm = params.documentForm;
  const isEditableGrid: boolean = documentForm !== undefined;
  const template: Template = params.template;

  const isGreenTaxOnlyTaxTemplate: boolean =
    getFieldFromSourceInTemplate(template, FieldTypes.Taxes) === undefined;

  if (isEditableGrid) {
    // EditableDatagrid use case
    const originalData: any = documentForm?.getState().values;
    const lineData = form.getState().values;
    const data: P2pData = cloneDeep(originalData);
    if (isGreenTaxOnlyTaxTemplate) {
      const greenTax = DataHelpers.getGreenTaxValue(
        DataHelpers.getGreenTaxNode(lineData, true /** singleTaxMode */)
      );
      set(lineData, FieldTypes.Taxes, [
        { _: FieldTypes.GT_Amount, value: greenTax },
      ]);
    }

    const currencyID = DataHelpers.getCurrencyID(data);
    const calculatedLineValues = LineProcessor.calculateLineValues(lineData);

    LineProcessor.calculateTaxesAndPrices(
      lineData,
      calculatedLineValues,
      currencyID
    );

    form.batch(() => {
      const properties = Object.keys(lineData).filter((t) => t !== 'id');
      properties.forEach((propName) =>
        form.change(propName, lineData[propName])
      );
    });
  } else {
    const originalData: any = form.getState().values;
    const data: P2pData = cloneDeep(originalData);
    if (isGreenTaxOnlyTaxTemplate) {
      data.lines?.forEach((line) => {
        const greenTax = DataHelpers.getGreenTaxValue(
          DataHelpers.getGreenTaxNode(line, true /** singleTaxMode */)
        );
        set(line, FieldTypes.Taxes, [
          { _: FieldTypes.GT_Amount, value: greenTax },
        ]);
      });
    }

    FormDataHelpers.recalculateTaxesAndPrices(data);

    // In case of MonoVat, reset monoVat if TaxSummary has more than one lines
    FormDataHelpers.manageMonoVat(data);

    // In case of Mono Tax Category, reset mono TaxCategory if TaxSummary has more than one lines
    FormDataHelpers.manageMonoTaxCategory(data);

    form.batch(() => {
      const properties = Object.keys(data).filter((t) => t !== 'id');
      properties.forEach((propName) => form.change(propName, data[propName]));
    });
  }
};

const reloadTemplate = async (
  dataProvider: DataProvider,
  recipientId: string,
  documentType: string,
  updateFormTemplate: (newTemplate: Template) => UpdateFormTemplateAction,
  showNotification: any,
  invoiceType?: string,
  invoiceCategory?: string
) => {
  const service = new TemplatesService(dataProvider);

  try {
    const template = await service.getTemplate(
      documentType,
      invoiceType,
      invoiceCategory,
      recipientId
    );
    updateFormTemplate(template);
  } catch (e) {
    showNotification('dxMessages.webForm.errorLoadTemplate', 'warning');
  }
};

/**
 * Reloads the form template
 */
const getInvoiceTemplate = async (params: OnChangeDoParamsInfo) => {
  const form = params.form;
  const data = form.getState().values;
  // Maps the fields schema identification name with the source name
  const recipientIdSource = getFieldSourceFromTemplate(
    params.template,
    params.onChangeDoInfo.recipientId
  );

  const invoiceTypeSource = getFieldSourceFromTemplate(
    params.template,
    params.onChangeDoInfo.invoiceType
  );

  const invoiceTypeCategoryCodeSource = getFieldSourceFromTemplate(
    params.template,
    params.onChangeDoInfo.invoiceCategoryCode
  );

  // Retrieves the current values of the involved fields
  let recipientId =
    params.emitter === recipientIdSource
      ? params.newValue
      : get(data, recipientIdSource);
  const regulatorExtraDetails = DataHelpers.regulatorExtraDetails(data as any);
  if (RegulatorExtraDetailsType.EFACTURA_OUTBOUND === regulatorExtraDetails) {
    recipientId = 'EFACTURA';
  }
  if (RegulatorExtraDetailsType.PEPPOL === regulatorExtraDetails) {
    recipientId = 'PEPPOL';
  }
  const invoiceType =
    params.emitter === invoiceTypeSource
      ? params.newValue
      : get(data, invoiceTypeSource);

  const invoiceCategoryCode =
    params.emitter === invoiceTypeCategoryCodeSource
      ? params.newValue
      : get(params.formData.properties, Ubl.invoiceTypeCode) ??
        InvoiceTypeCode.COMMERCIAL;

  await reloadTemplate(
    params.dataProvider,
    recipientId,
    DocumentTypeCode.INVOIC,
    params.updateFormTemplate,
    params.showNotification,
    invoiceType,
    invoiceCategoryCode
  );
};

/**
 * Reloads the form template
 */
const getDespatchAdviceTemplate = async (params: OnChangeDoParamsInfo) => {
  // Maps the fields params.template identification name with the source name
  const recipientIdSource = getFieldSourceFromTemplate(
    params.template,
    params.onChangeDoInfo.recipientId
  );

  if (params.emitter === recipientIdSource) {
    // await reloadTemplate(
    //   params.dataProvider,
    //   params.newValue as string,
    //   DocumentTypeCode.DESADV,
    //   params.updateFormTemplate,
    //   params.showNotification
    // );
  }
};

/**
 * Used by changeCustomer function
 */
interface ChangeCustomerInfo extends OnChangeFunctionInfo {
  invoiceType: string;
  customerId: string;
  customerName: string;
  customerGLN: string;
  payerIban: string;
  payerBank: string;
  customerBuildingNumber: string;
  customerStreetName: string;
  customerCityName: string;
  customerPostalCode: string;
  customerCountryCode: string;
  deliveryLocationName: string;
  deliveryBuildingNumber: string;
  deliveryStreetName: string;
  deliveryCityName: string;
  deliveryPostalZone: string;
  deliveryCountry: string;
}

/**
 * Fills the form with customer details when customer id changes
 */
const changeCustomer = async (
  params: OnChangeDoParamsInfo<ChangeCustomerInfo>
) => {
  const companyService = new CompanyService(params.dataProvider);
  const form = params.form;
  const data = form.getState().values;

  const customerIdField = getFieldSourceFromTemplate(
    params.template,
    params.onChangeDoInfo.customerId
  );

  const customerIdValue =
    params.emitter === customerIdField
      ? params.newValue
      : get(data, customerIdField);

  let customerDetails: CompanyModel | undefined = undefined;
  try {
    customerDetails = await companyService.getDetails(customerIdValue);
  } catch (e) {
    //params.showNotification('dxMessages.error_messages.generic_error', 'error');
  }

  const fillValue = (
    field: string,
    getDetailsValue: (details: CompanyModel | undefined) => any
  ) => {
    let formField = getFieldSourceFromTemplate(params.template, field);
    formField = getSourceField(formField);
    const detailsValue = getDetailsValue(customerDetails);
    let value = detailsValue ?? '';

    form.change(formField, value);
  };

  fillValue(
    params.onChangeDoInfo.customerGLN,
    (details) => details?.billingAddress?.gln
  );

  // find the right shipment address according to delivery GLN
  const deliveryGLN = get(data, Ubl.deliveryLocationGLN);
  let shippingAddress: CompanyAddress | undefined = first(
    customerDetails?.shippingAddress?.filter(
      (address) => address.gln === deliveryGLN
    )
  );
  if (!shippingAddress && customerDetails && customerDetails.shippingAddress) {
    shippingAddress = customerDetails.shippingAddress[0];
  }
  fillAnyValue(
    params.onChangeDoInfo.deliveryLocationName,
    shippingAddress?.name,
    params
  );
  fillAnyValue(
    params.onChangeDoInfo.deliveryBuildingNumber,
    shippingAddress?.buildingNumber,
    params
  );
  fillAnyValue(
    params.onChangeDoInfo.deliveryStreetName,
    shippingAddress?.street,
    params
  );
  fillAnyValue(
    params.onChangeDoInfo.deliveryCityName,
    shippingAddress?.city,
    params
  );
  fillAnyValue(
    params.onChangeDoInfo.deliveryPostalZone,
    shippingAddress?.postalCode,
    params
  );
  fillAnyValue(
    params.onChangeDoInfo.deliveryCountry,
    shippingAddress?.idCountry,
    params
  );

  fillValue(params.onChangeDoInfo.customerName, (details) => details?.name);

  fillValue(
    params.onChangeDoInfo.payerBank,
    (details) => details?.billingAddress?.bank
  );

  fillValue(
    params.onChangeDoInfo.payerIban,
    (details) => details?.billingAddress?.financialAccount
  );

  fillValue(
    params.onChangeDoInfo.customerBuildingNumber,
    (details) => details?.billingAddress?.buildingNumber
  );

  fillValue(
    params.onChangeDoInfo.customerStreetName,
    (details) => details?.billingAddress?.street
  );

  fillValue(
    params.onChangeDoInfo.customerCityName,
    (details) => details?.billingAddress?.city
  );

  fillValue(
    params.onChangeDoInfo.customerPostalCode,
    (details) => details?.billingAddress?.postalCode
  );

  fillValue(
    params.onChangeDoInfo.customerCountryCode,
    (details) => details?.billingAddress?.idCountry
  );

  const invoiceTypeField = getFieldSourceFromTemplate(
    params.template,
    params.onChangeDoInfo.invoiceType
  );

  const invoiceTypeValue =
    params.emitter === invoiceTypeField
      ? params.newValue
      : get(data, invoiceTypeField);

  const invoiceCategoryCode =
    get(params.formData.properties, Ubl.invoiceTypeCode) ??
    InvoiceTypeCode.COMMERCIAL;

  await reloadTemplate(
    params.dataProvider,
    customerIdValue,
    DocumentTypeCode.INVOIC,
    params.updateFormTemplate,
    params.showNotification,
    invoiceTypeValue,
    invoiceCategoryCode
  );
};

interface ChangeInvoiceReferenceInfo extends OnChangeFunctionInfo {
  invoiceReferenceUuid: string;
  invoiceReferenceIssueDate: string;
  invoiceReferenceId: string;
  invoiceReferenceTypeCode: string;
}

/**
 * Fills the form with WayBill invoice reference details
 */
const changeInvoiceReference = async (
  params: OnChangeDoParamsInfo<ChangeInvoiceReferenceInfo>
) => {
  const invoiceService = DocumentServiceFactory.create(
    InvoiceServiceCreator,
    params.dataProvider
  ) as InvoiceService;

  const filter = {
    [Metadata.processDocumentFormatType]:
      Constants.PROCESS_DOCUMENT_FORMAT_TYPE_DRAFT,
    [Metadata.processStatus]: ProcessStatus.DRAFT,
  };

  let invoices: any | undefined = undefined;
  invoices = await invoiceService.getInvoices(filter);

  const invoiceIdItems = invoices?.map((r) => {
    return {
      uid: get(r.properties, Metadata.dxuid),
      id: get(r.properties, Metadata.documentId),
      date: get(r.properties, Metadata.issueDate),
    };
  });

  const newInvoiceReference = invoiceIdItems?.filter(
    (invoice) => invoice.uid === params.newValue
  );

  fillAnyValue(
    params.onChangeDoInfo.invoiceReferenceIssueDate,
    newInvoiceReference[0]?.date,
    params
  );

  fillAnyValue(
    params.onChangeDoInfo.invoiceReferenceId,
    newInvoiceReference[0]?.id,
    params
  );

  fillAnyValue(
    params.onChangeDoInfo.invoiceReferenceTypeCode,
    DocumentTypeCode.INVOIC,
    params
  );
};

interface ChangeLocationInfo extends OnChangeFunctionInfo {
  buildingNumber: string;
  street: string;
  additionalStreet: string;
  city: string;
  postalCode: string;
  countryCode: string;
}

/**
 * Fills the form with WayBill invoice reference details
 */
const changeLocation = async (
  params: OnChangeDoParamsInfo<ChangeLocationInfo>
) => {
  const companyService = new CompanyService(params.dataProvider);
  const recipientId = get(params.formData.properties, Metadata.recipientId);
  const details: CompanyModel = await companyService.getDetails(recipientId);

  const newLocation = details.logisticAddress
    ?.map((l) => {
      return {
        // map street to name V2-WORKAROUND
        name: l.name,
        street: l.street,
        additionalStreet: l.additionalStreet,
        buildingNumber: l.buildingNumber,
        city: l.city,
        countryCode: l.idCountry,
        postalCode: l.postalCode,
        gln: l.gln,
      };
    })
    .filter((l) => l.gln === params.newValue);

  fillAnyValue(
    params.onChangeDoInfo.buildingNumber,
    newLocation[0]?.buildingNumber,
    params
  );
  fillAnyValue(params.onChangeDoInfo.street, newLocation[0]?.street, params);
  fillAnyValue(
    params.onChangeDoInfo.additionalStreet,
    newLocation[0]?.additionalStreet,
    params
  );
  fillAnyValue(params.onChangeDoInfo.city, newLocation[0]?.city, params);
  fillAnyValue(
    params.onChangeDoInfo.countryCode,
    newLocation[0]?.countryCode,
    params
  );
  fillAnyValue(
    params.onChangeDoInfo.postalCode,
    newLocation[0]?.postalCode,
    params
  );
};

interface ChangeLocationInfo extends OnChangeFunctionInfo {
  buildingNumber: string;
  street: string;
  city: string;
  postalCode: string;
  countryCode: string;
  gln: string;
  locationName?: string;
}

const changeDeliveryLocation = async (
  params: OnChangeDoParamsInfo<ChangeLocationInfo>
) => {
  const companyService = new CompanyService(params.dataProvider);
  const recipientId = get(params.formData.properties, Metadata.recipientId);
  const details: CompanyModel = await companyService.getDetails(recipientId);
  const newAddress = first(
    details.shippingAddress?.filter((l) => l.gln === params.newValue)
  );
  changeAddress(newAddress, params);
};

interface ChangePeppolCustomerName extends ManagePeppolCountrySpecifics {
  accessPoint: string;
}

export const changePeppolCustomerName = async (
  params: OnChangeDoParamsInfo<ChangePeppolCustomerName>
) => {
  const form = params.form;
  const originalData: any = form.getState().values;
  const newData: P2pData = cloneDeep(originalData);
  const peppolRegistryId = params.newValue;
  const companyService = new CompanyService(params.dataProvider);
  const peppolService = new PeppolService();
  const endpoint: any = await companyService.fetchEndpoints(
    peppolRegistryId as string
  );
  const countryId = endpoint[0].country;
  const recipientId = endpoint[0].companyIdentification || '';
  const companyName = endpoint[0].companyName || '';
  const schemaId =
    '0'.repeat(4 - endpoint[0].schemaId.toString().length) +
    endpoint[0].schemaId;
  const valueId = endpoint[0].valueId;
  set(newData, FieldTypes.PEPPOL_ACCESS_POINT, schemaId + ':' + valueId);
  set(newData, Ubl.accountingCustomerGLN, valueId);
  set(newData, Ubl.accountingCustomerGLNSchemeID, schemaId);
  set(newData, Ubl.accountingCustomerCountryCode, countryId);
  set(newData, 'properties.' + Metadata.recipientId, recipientId);
  set(newData, 'properties.' + Metadata.recipientName, companyName);
  set(newData, FieldTypes.PEPPOL_COMPANY_NAME, peppolRegistryId);

  const capabilities: PeppolProfileDocument[] =
    await peppolService.getEndpointCapabilities(
      schemaId + ':' + valueId,
      'Invoice'
    );

  setPeppolAPSpecifcs(
    form,
    params.template,
    newData,
    countryId,
    params,
    capabilities
  );

  form.batch(() => {
    const properties = Object.keys(newData).filter((t) => t !== 'id');
    properties.forEach((propName) => form.change(propName, newData[propName]));
  });
};

const changeShipmentLocation = async (
  params: OnChangeDoParamsInfo<ChangeLocationInfo>
) => {
  const companyService = new CompanyService(params.dataProvider);
  const details: CompanyModel = await companyService.getDetails();

  const shippingAddress = first(
    details.shippingAddress?.filter((l) => l.gln === params.newValue)
  );

  changeAddress(shippingAddress, params);
};

/**
 * Fills the form with delivery location details
 */
const changeAddress = async (
  newAddress: CompanyAddress | undefined,
  params: OnChangeDoParamsInfo<ChangeLocationInfo>
) => {
  if (!newAddress) {
    return;
  }

  fillAnyValue(
    params.onChangeDoInfo.buildingNumber,
    newAddress?.buildingNumber,
    params
  );
  fillAnyValue(
    params.onChangeDoInfo.street,
    newAddress.street === '' ? newAddress.name : newAddress.street,
    params
  );
  fillAnyValue(params.onChangeDoInfo.city, newAddress?.city, params);
  fillAnyValue(
    params.onChangeDoInfo.countryCode,
    newAddress?.idCountry,
    params
  );
  fillAnyValue(
    params.onChangeDoInfo.postalCode,
    newAddress?.postalCode,
    params
  );
  fillAnyValue(params.onChangeDoInfo.gln, newAddress?.gln, params);
  fillAnyValue(params.onChangeDoInfo.locationName, newAddress?.name, params);
};

interface DispatchValue extends OnChangeFunctionInfo {
  targets: string[];
}
/**
 * Dispatch the newValue to targets
 */
const dispatchValue = async (params: OnChangeDoParamsInfo<DispatchValue>) => {
  const valueToDispatch = params.newValue;

  params.onChangeDoInfo.targets.forEach((target) => {
    fillAnyValue(target, valueToDispatch, params);
  });
};

interface SetDespatchReference extends OnChangeFunctionInfo {
  despatchAdviceDocumentTypeCode: string;
  despatchAdviceReference: string;
}

const setDespatchAdviceReference = async (
  params: OnChangeDoParamsInfo<SetDespatchReference>
) => {
  const despatchAdviceId = params.newValue;
  if (despatchAdviceId) {
    fillAnyValue(
      params.onChangeDoInfo.despatchAdviceDocumentTypeCode,
      DocumentTypeCode.DESADV,
      params
    );
  } else {
    fillAnyValue(
      params.onChangeDoInfo.despatchAdviceDocumentTypeCode,
      '',
      params
    );
  }
};

interface HideUnhideFields extends OnChangeFunctionInfo {
  hideParameters: {
    condition: 'equal' | 'contains' | 'greaterThan' | 'lessThan';
    value: any;
    targets: string[];
  };
}

/**
 * Hides/Unhides fields on condition ...
 * @param params
 */
const hideUnhideFields = async (
  params: OnChangeDoParamsInfo<HideUnhideFields>
) => {
  let newTemplate: Template = params.template;
  const conditionalValue = params.onChangeDoInfo.hideParameters.value;
  const newValue = params.newValue;
  if (
    newValue != null &&
    newValue !== undefined &&
    conditionalValue !== undefined
  ) {
    params.onChangeDoInfo.hideParameters.targets.forEach((target) => {
      switch (params.onChangeDoInfo.hideParameters.condition) {
        case 'equal':
          newTemplate = setFieldProperty(
            newTemplate,
            target,
            'options.hidden',
            newValue === conditionalValue
          );
          break;
        case 'contains':
          newTemplate = setFieldProperty(
            newTemplate,
            target,
            'options.hidden',
            `${newValue}`.indexOf(`${conditionalValue}`) !== -1
          );
          break;
        case 'greaterThan':
          newTemplate = setFieldProperty(
            newTemplate,
            target,
            'options.hidden',
            newValue > conditionalValue
          );
          break;
        case 'lessThan':
          newTemplate = setFieldProperty(
            newTemplate,
            target,
            'options.hidden',
            newValue < conditionalValue
          );
          break;
        default:
          throw new Error(
            `Change function hideUnhideFields : unknown condition parameter : ${params.onChangeDoInfo.hideParameters.condition}`
          );
      }
    });
    if (params.template.model !== newTemplate.model) {
      // params.updateFormTemplate(newSchema);
    }
  }
};

interface ComputeTransportCost extends OnChangeFunctionInfo {
  computeTransportCostParams: {
    withoutFees: string;
    fees: string;
    withFees: string;
  };
}

/**
 * Compute Transport Cost
 * @param params
 */
const computeTransportCost = async (
  params: OnChangeDoParamsInfo<ComputeTransportCost>
) => {
  const form = params.form;
  const data = form.getState().values;

  const withoutFeesSource = getFieldSourceFromTemplate(
    params.template,
    params.onChangeDoInfo.computeTransportCostParams.withoutFees
  );

  const withoutFees =
    params.emitter === withoutFeesSource
      ? params.newValue
      : get(data, withoutFeesSource) || 0;

  const feesSource = getFieldSourceFromTemplate(
    params.template,
    params.onChangeDoInfo.computeTransportCostParams.fees
  );

  const fees =
    params.emitter === feesSource
      ? params.newValue
      : get(data, feesSource) || 0;

  const withFeesSource = getFieldSourceFromTemplate(
    params.template,
    params.onChangeDoInfo.computeTransportCostParams.withFees
  );
  form.change(withFeesSource, round(withoutFees + fees));
};

interface DescriptionTypeManagementInfo extends OnChangeFunctionInfo {
  descriptionTypeText: string;
  fieldsToUpdate: string[];
}
/**
 * Transport description type management
 * @param params
 */
const descriptionTypeManagement = async (
  params: OnChangeDoParamsInfo<DescriptionTypeManagementInfo>
) => {
  let newTemplate: Template = params.template;
  const fieldsToUpdate = params.onChangeDoInfo.fieldsToUpdate;
  let isRequired: boolean = false;
  const form = params.form;
  const data = form.getState().values;

  const descriptionType = WaybillService.getWaybillServiceDescriptionText(
    params.newValue
  );
  if (
    params.newValue === ServiceDescriptionCode.GLASS_SALE ||
    params.newValue === ServiceDescriptionCode.GLASS_ACQUISITION ||
    params.newValue === ServiceDescriptionCode.FEES
  ) {
    isRequired = true;
  }
  fillAnyValue(
    params.onChangeDoInfo.descriptionTypeText,
    params.translate(descriptionType, { _: descriptionType }),
    params
  );
  fieldsToUpdate.forEach((field) => {
    const fieldSource = getFieldSourceFromTemplate(newTemplate, field);
    if (
      fieldSource ===
        'ublProperties.Waybill[0].DocumentReference[1].ID[0].IdentifierContent' &&
      params.newValue === ServiceDescriptionCode.GLASS_ACQUISITION
    ) {
      // Despatch Advice ID is optional for GLASS ACQUISITION
      isRequired = false;
    }
    newTemplate = setFieldProperty(newTemplate, field, 'required', isRequired);
  });
  if (params.template.model !== newTemplate.model) {
    params.updateFormTemplate(newTemplate);
    fieldsToUpdate.forEach((field) => {
      const fieldSource = getFieldSourceFromTemplate(newTemplate, field);
      let value = get(data, fieldSource);
      if (!value) {
        resetField(form.change, fieldSource, value);
      } else {
        form.change(fieldSource, value);
      }
    });
  }
};

interface SetFieldsRequired extends OnChangeFunctionInfo {
  targets: string[];
  values: any[];
}

/**
 * Set Field required or not on condition ...
 * @param params
 */
const setFieldsRequired = async (
  params: OnChangeDoParamsInfo<SetFieldsRequired>
) => {
  let newSchema: Template = params.template;
  const values: any[] = params.onChangeDoInfo.values;
  const newValue: any = params.newValue;
  const form = params.form;
  const data = form.getState().values;
  if (newValue !== undefined) {
    params.onChangeDoInfo.targets.forEach((target) => {
      newSchema = setFieldProperty(
        newSchema,
        target,
        'required',
        values.indexOf(newValue) !== -1
      );
    });
    if (params.template.model !== newSchema.model) {
      params.updateFormTemplate(newSchema);
      params.onChangeDoInfo.targets.forEach((target) => {
        const fieldSource = getFieldSourceFromTemplate(newSchema, target);
        let value = get(data, fieldSource);
        resetField(form.change, fieldSource, value);
      });
    }
  }
};

interface ApplyMonoVat extends OnChangeFunctionInfo {
  source?: string;
}

/**
 * Sets lines VAT percentage values according to a global selection
 * @param params
 */
const applyMonoVat = async (params: OnChangeDoParamsInfo<ApplyMonoVat>) => {
  const form = params.form;
  const originalData: any = form.getState().values;
  const newData: P2pData = cloneDeep(originalData);
  const newValue: any = params.newValue;
  let newLines = newData.lines;
  const isOrder: boolean =
    DataHelpers.getDocumentTypeCode(newData) === DocumentTypeCode.ORDERS;
  const defaultValuePath = isOrder
    ? 'LineItem[0].TaxTotal[0].TaxSubtotal[0].Percent[0]._'
    : Ubl.vatPercentage;
  const valuePath = params.onChangeDoInfo.source ?? defaultValuePath;
  newLines.forEach((line, index) => {
    if (!LineProcessor.isLineSGR(line)) {
      set(newLines[index], valuePath, newValue);
    }
  });
  set(newData, 'lines', newLines);

  FormDataHelpers.recalculateTaxesAndPrices(newData);

  set(
    newData,
    isOrder ? OrderService.MONO_VAT_FIELD : FieldTypes.MONO_VAT,
    params.newValue
  );

  form.batch(() => {
    const properties = Object.keys(newData).filter((t) => t !== 'id');
    properties.forEach((propName) => form.change(propName, newData[propName]));
  });
};

interface ApplyTaxCategory extends OnChangeFunctionInfo {}

/**
 * Sets lines tax category value according to a global selection
 * @param params
 */
const applyTaxCategory = async (
  params: OnChangeDoParamsInfo<ApplyTaxCategory>
) => {
  const form = params.form;
  const originalData: any = form.getState().values;
  const newData: P2pData = cloneDeep(originalData);
  const newValue: any = params.newValue;
  let newLines = newData.lines;
  const regulatorExtraDetails = DataHelpers.regulatorExtraDetails(
    newData.properties
  );
  newLines.forEach((line, index) => {
    if (!LineProcessor.isLineSGR(line)) {
      set(newLines[index], Ubl.taxSchemeName, newValue);
      if (RegulatorExtraDetailsType.PEPPOL === regulatorExtraDetails) {
        set(newLines[index], Ubl.taxSchemeID, newValue);
      }
    }
  });
  set(newData, 'lines', newLines);

  FormDataHelpers.recalculateTaxesAndPrices(newData);

  set(newData, FieldTypes.TAX_CATEGORY, params.newValue);

  form.batch(() => {
    const properties = Object.keys(newData).filter((t) => t !== 'id');
    properties.forEach((propName) => form.change(propName, newData[propName]));
  });
};

const manageTaxCategory = (
  newValue: any,
  taxExemptionReasonCode: string,
  line: any
) => {
  switch (newValue) {
    case Constants.TAX_SCHEME_ID_AE:
      set(line as InvoiceLine, Ubl.vatPercentage, 0);
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReasonCode,
        Constants.TAX_EXEMPTION_CODE_VATEX_EU_AE
      );
      break;
    case Constants.TAX_SCHEME_ID_O:
      set(line as InvoiceLine, Ubl.vatPercentage, 0);
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReasonCode,
        Constants.TAX_EXEMPTION_CODE_VATEX_EU_O
      );
      break;
    case Constants.TAX_SCHEME_ID_K:
      set(line as InvoiceLine, Ubl.vatPercentage, 0);
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReasonCode,
        Constants.TAX_EXEMPTION_CODE_VATEX_EU_IC
      );
      break;
    case Constants.TAX_SCHEME_ID_G:
      set(line as InvoiceLine, Ubl.vatPercentage, 0);
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReasonCode,
        Constants.TAX_EXEMPTION_CODE_VATEX_EU_G
      );
      break;
    case Constants.TAX_SCHEME_ID_E:
      set(line as InvoiceLine, Ubl.vatPercentage, 0);
      if (
        taxExemptionReasonCode !== Constants.TAX_EXEMPTION_CODE_VATEX_EU_C &&
        taxExemptionReasonCode !== Constants.TAX_EXEMPTION_CODE_VATEX_EU_D &&
        taxExemptionReasonCode !== Constants.TAX_EXEMPTION_CODE_VATEX_EU_F &&
        taxExemptionReasonCode !== Constants.TAX_EXEMPTION_CODE_VATEX_EU_I &&
        taxExemptionReasonCode !== Constants.TAX_EXEMPTION_CODE_VATEX_EU_J &&
        taxExemptionReasonCode !== Constants.TAX_EXEMPTION_CODE_VATEX_EU_79_C &&
        taxExemptionReasonCode !== Constants.TAX_EXEMPTION_CODE_VATEX_EU_132 &&
        taxExemptionReasonCode !== Constants.TAX_EXEMPTION_CODE_VATEX_EU_143 &&
        taxExemptionReasonCode !== Constants.TAX_EXEMPTION_CODE_VATEX_EU_148 &&
        taxExemptionReasonCode !== Constants.TAX_EXEMPTION_CODE_VATEX_EU_151 &&
        taxExemptionReasonCode !== Constants.TAX_EXEMPTION_CODE_VATEX_EU_309 &&
        taxExemptionReasonCode !==
          Constants.TAX_EXEMPTION_CODE_VATEX_FR_FRANCHISE
      ) {
        unset(line as InvoiceLine, Ubl.taxExemptionReasonCode);
      }
      break;
    case Constants.TAX_SCHEME_ID_Z:
      set(line as InvoiceLine, Ubl.vatPercentage, 0);
      unset(line as InvoiceLine, Ubl.taxExemptionReasonCode);
      break;
    case Constants.TAX_SCHEME_NAME_S_01:
      unset(line as InvoiceLine, Ubl.taxExemptionReasonCode);
      unset(line as InvoiceLine, Ubl.taxExemptionReason);
      set(line as InvoiceLine, Ubl.vatPercentage, 6);
      break;
    case Constants.TAX_SCHEME_NAME_S_02:
      unset(line as InvoiceLine, Ubl.taxExemptionReasonCode);
      unset(line as InvoiceLine, Ubl.taxExemptionReason);
      set(line as InvoiceLine, Ubl.vatPercentage, 12);
      break;
    case Constants.TAX_SCHEME_NAME_S_03:
      unset(line as InvoiceLine, Ubl.taxExemptionReasonCode);
      unset(line as InvoiceLine, Ubl.taxExemptionReason);
      set(line as InvoiceLine, Ubl.vatPercentage, 21);
      break;
    case Constants.TAX_SCHEME_NAME_45:
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReasonCode,
        Constants.TAX_EXEMPTION_CODE_BETE_45
      );
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReason,
        'Reverse charge - Contractor'
      );
      set(line as InvoiceLine, Ubl.vatPercentage, 0);
      break;
    case Constants.TAX_SCHEME_NAME_NA:
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReasonCode,
        Constants.TAX_EXEMPTION_CODE_BETE_EX
      );
      set(line as InvoiceLine, Ubl.taxExemptionReason, 'Exempt');
      set(line as InvoiceLine, Ubl.vatPercentage, 0);
      break;
    case Constants.TAX_SCHEME_NAME_46_GO:
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReasonCode,
        Constants.TAX_EXEMPTION_CODE_BETE_46_GO
      );
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReason,
        'Intra-community supply - Goods'
      );
      set(line as InvoiceLine, Ubl.vatPercentage, 0);
      break;
    case Constants.TAX_SCHEME_NAME_46_TR:
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReasonCode,
        Constants.TAX_EXEMPTION_CODE_BETE_46_TR
      );
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReason,
        'Intra-community supply - Triangle a-B-c'
      );
      set(line as InvoiceLine, Ubl.vatPercentage, 0);
      break;
    case Constants.TAX_SCHEME_NAME_44:
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReasonCode,
        Constants.TAX_EXEMPTION_CODE_BETE_44
      );
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReason,
        'Intra-community supply - Services B2B'
      );
      set(line as InvoiceLine, Ubl.vatPercentage, 0);
      break;
    case Constants.TAX_SCHEME_NAME_47_EX:
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReasonCode,
        Constants.TAX_EXEMPTION_CODE_BETE_47_EX
      );
      set(line as InvoiceLine, Ubl.taxExemptionReason, 'Export non E.U.');
      set(line as InvoiceLine, Ubl.vatPercentage, 0);
      break;
    case Constants.TAX_SCHEME_NAME_47_EI:
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReasonCode,
        Constants.TAX_EXEMPTION_CODE_BETE_47_EI
      );
      set(line as InvoiceLine, Ubl.taxExemptionReason, 'Indirect export');
      set(line as InvoiceLine, Ubl.vatPercentage, 0);
      break;
    case Constants.TAX_SCHEME_NAME_47_EE:
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReasonCode,
        Constants.TAX_EXEMPTION_CODE_BETE_47_EE
      );
      set(line as InvoiceLine, Ubl.taxExemptionReason, 'Export via E.U.');
      set(line as InvoiceLine, Ubl.vatPercentage, 0);
      break;
    case Constants.TAX_SCHEME_NAME_47_TO:
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReasonCode,
        Constants.TAX_EXEMPTION_CODE_BETE_47_TO
      );
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReason,
        'Intra-community supply - Manufacturing cost'
      );
      set(line as InvoiceLine, Ubl.vatPercentage, 0);
      break;
    case Constants.TAX_SCHEME_NAME_47_AS:
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReasonCode,
        Constants.TAX_EXEMPTION_CODE_BETE_47_AS
      );
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReason,
        'Intra-community supply - Assembly'
      );
      set(line as InvoiceLine, Ubl.vatPercentage, 0);
      break;
    case Constants.TAX_SCHEME_NAME_47_DI:
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReasonCode,
        Constants.TAX_EXEMPTION_CODE_BETE_47_DI
      );
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReason,
        'Intra-community supply - Distance'
      );
      set(line as InvoiceLine, Ubl.vatPercentage, 0);
      break;

    case Constants.TAX_SCHEME_NAME_47_SE:
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReasonCode,
        Constants.TAX_EXEMPTION_CODE_BETE_47_SE
      );
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReason,
        'Intra-community supply - Services'
      );
      set(line as InvoiceLine, Ubl.vatPercentage, 0);
      break;

    case Constants.TAX_SCHEME_NAME_SC:
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReasonCode,
        Constants.TAX_EXEMPTION_CODE_BETE_SC
      );
      set(line as InvoiceLine, Ubl.taxExemptionReason, 'Small company');
      set(line as InvoiceLine, Ubl.vatPercentage, 0);
      break;
    case Constants.TAX_SCHEME_NAME_00_44:
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReasonCode,
        Constants.TAX_EXEMPTION_CODE_BETE_00_44
      );
      set(line as InvoiceLine, Ubl.taxExemptionReason, '0% Clause 44');
      set(line as InvoiceLine, Ubl.vatPercentage, 0);
      break;
    case Constants.TAX_SCHEME_NAME_NS:
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReasonCode,
        Constants.TAX_EXEMPTION_CODE_BETE_NS
      );
      set(line as InvoiceLine, Ubl.taxExemptionReason, 'Not subject to VAT');
      break;
    case Constants.TAX_SCHEME_NAME_OSS_S:
    case Constants.TAX_SCHEME_NAME_OSS_I:
    case Constants.TAX_SCHEME_NAME_OSS_G:
      unset(line as InvoiceLine, Ubl.taxExemptionReasonCode);
      unset(line as InvoiceLine, Ubl.taxExemptionReason);
      break;

    case Constants.TAX_SCHEME_NAME_FD:
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReasonCode,
        Constants.TAX_EXEMPTION_CODE_BETE_FD
      );
      set(line as InvoiceLine, Ubl.taxExemptionReason, 'Financial discount');
      set(line as InvoiceLine, Ubl.vatPercentage, 0);
      break;
    case Constants.TAX_SCHEME_NAME_03_SE:
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReasonCode,
        Constants.TAX_EXEMPTION_CODE_BETE_03_SE
      );
      set(line as InvoiceLine, Ubl.taxExemptionReason, 'Standard exchange');
      set(line as InvoiceLine, Ubl.vatPercentage, 0);
      break;
    case Constants.TAX_SCHEME_NAME_MA:
      set(
        line as InvoiceLine,
        Ubl.taxExemptionReasonCode,
        Constants.TAX_EXEMPTION_CODE_BETE_MA
      );
      set(line as InvoiceLine, Ubl.taxExemptionReason, 'Margin');
      set(line as InvoiceLine, Ubl.vatPercentage, 0);
      break;

    default:
      unset(line as InvoiceLine, Ubl.taxExemptionReasonCode);
  }
};

interface ApplyLineTaxCategory extends OnChangeFunctionInfo {}

/**
 * Sets line tax category value.
 * Sets VAT percentage and Tax Exemption Reason Code accordingly
 * @param params
 */
const applyLineTaxCategory = async (
  params: OnChangeDoParamsInfo<ApplyLineTaxCategory>
) => {
  const form = params.form;
  const originalData: any = form.getState().values;
  const documentForm = params.documentForm;
  const documentFormData: any = documentForm?.getState().values; // EditableDatagrid
  const newData: P2pData = cloneDeep(originalData);
  const { lineIndex } = getEmitterInLinesTable(params.emitter);
  const newValue: any = params.newValue;

  if (!documentFormData) {
    // Invoice up to 49 lines
    let newLines = newData.lines;
    const line = newLines[lineIndex];
    const taxExemptionReasonCode = get(
      line as InvoiceLine,
      Ubl.taxExemptionReasonCode
    );
    if (!LineProcessor.isLineSGR(line)) {
      manageTaxCategory(newValue, taxExemptionReasonCode, line);
    }
    set(newData, 'lines', newLines);

    FormDataHelpers.recalculateTaxesAndPrices(newData);

    // In case of MonoVat, reset monoVat if TaxSummary has more than one lines
    FormDataHelpers.manageMonoVat(newData);

    // In case of Mono Tax Category, reset mono TaxCategory if TaxSummary has more than one lines
    FormDataHelpers.manageMonoTaxCategory(newData);

    form.batch(() => {
      const properties = Object.keys(newData).filter((t) => t !== 'id');
      properties.forEach((propName) =>
        form.change(propName, newData[propName])
      );
    });
  } else {
    // Invoice with more than 49 lines
    const line: any = form?.getState().values;
    const documentData: P2pData = cloneDeep(documentFormData);
    const currencyID = DataHelpers.getCurrencyID(documentData);
    const taxExemptionReasonCode = get(
      line as InvoiceLine,
      Ubl.taxExemptionReasonCode
    );

    if (!LineProcessor.isLineSGR(line)) {
      manageTaxCategory(newValue, taxExemptionReasonCode, line);
    }
    const calculatedLineValues = LineProcessor.calculateLineValues(line);

    LineProcessor.calculateTaxesAndPrices(
      line,
      calculatedLineValues,
      currencyID
    );

    form.batch(() => {
      const properties = {
        taxSchemeID: Ubl.taxSchemeID,
        taxSchemeName: Ubl.taxSchemeName,
        taxCategoryCode: Ubl.taxCategoryCode,
        taxExemptionReasonCode: Ubl.taxExemptionReasonCode,
        taxExemptionReason: Ubl.taxExemptionReason,
        vatPercentage: Ubl.vatPercentage,
      };
      Object.keys(properties).forEach((propName) => {
        // update a (same) placeholder for each updated values in order to trigger validation
        form.change('waste', get(line, properties[propName]));
      });
    });
  }
};

interface ApplyLineTaxExemptionReasonCode extends OnChangeFunctionInfo {}

/**
 * Sets line tax category exemption reason Code value.
 * Sets Tax category code (here setting taxSchemeName then the computation will act on TaxSchemeID) and VAT percentage accordingly
 * @param params
 */
const applyLineTaxExemptionReasonCode = async (
  params: OnChangeDoParamsInfo<ApplyLineTaxExemptionReasonCode>
) => {
  const form = params.form;
  const originalData: any = form.getState().values;
  const documentForm = params.documentForm;
  const documentFormData: any = documentForm?.getState().values; // EditableDatagrid
  const newData: P2pData = cloneDeep(originalData);
  const { lineIndex } = getEmitterInLinesTable(params.emitter);
  const newValue: any = params.newValue;

  let newLines = newData.lines;
  const line = documentFormData ? form?.getState().values : newLines[lineIndex];

  if (!LineProcessor.isLineSGR(line)) {
    switch (newValue) {
      case Constants.TAX_EXEMPTION_CODE_VATEX_EU_AE:
        set(line as InvoiceLine, Ubl.vatPercentage, 0);
        set(line as InvoiceLine, Ubl.taxSchemeName, Constants.TAX_SCHEME_ID_AE);
        break;
      case Constants.TAX_EXEMPTION_CODE_VATEX_EU_O:
        set(line as InvoiceLine, Ubl.vatPercentage, 0);
        set(line as InvoiceLine, Ubl.taxSchemeName, Constants.TAX_SCHEME_ID_O);
        break;
      case Constants.TAX_EXEMPTION_CODE_VATEX_EU_IC:
        set(line as InvoiceLine, Ubl.vatPercentage, 0);
        set(line as InvoiceLine, Ubl.taxSchemeName, Constants.TAX_SCHEME_ID_K);
        break;
      case Constants.TAX_EXEMPTION_CODE_VATEX_EU_G:
        set(line as InvoiceLine, Ubl.vatPercentage, 0);
        set(line as InvoiceLine, Ubl.taxSchemeName, Constants.TAX_SCHEME_ID_G);
        break;
      case Constants.TAX_EXEMPTION_CODE_VATEX_EU_C:
      case Constants.TAX_EXEMPTION_CODE_VATEX_EU_D:
      case Constants.TAX_EXEMPTION_CODE_VATEX_EU_F:
      case Constants.TAX_EXEMPTION_CODE_VATEX_EU_I:
      case Constants.TAX_EXEMPTION_CODE_VATEX_EU_J:
      case Constants.TAX_EXEMPTION_CODE_VATEX_EU_79_C:
      case Constants.TAX_EXEMPTION_CODE_VATEX_EU_132:
      case Constants.TAX_EXEMPTION_CODE_VATEX_EU_143:
      case Constants.TAX_EXEMPTION_CODE_VATEX_EU_148:
      case Constants.TAX_EXEMPTION_CODE_VATEX_EU_151:
      case Constants.TAX_EXEMPTION_CODE_VATEX_EU_309:
      case Constants.TAX_EXEMPTION_CODE_VATEX_FR_FRANCHISE:
        set(line as InvoiceLine, Ubl.vatPercentage, 0);
        set(line as InvoiceLine, Ubl.taxSchemeName, Constants.TAX_SCHEME_ID_E);
        break;

      case Constants.TAX_EXEMPTION_CODE_BETE_EX:
        set(line as InvoiceLine, Ubl.vatPercentage, 0);
        set(
          line as InvoiceLine,
          Ubl.taxSchemeName,
          Constants.TAX_SCHEME_NAME_NA
        );
        break;
      case Constants.TAX_EXEMPTION_CODE_BETE_FD:
        set(line as InvoiceLine, Ubl.vatPercentage, 0);
        set(
          line as InvoiceLine,
          Ubl.taxSchemeName,
          Constants.TAX_SCHEME_NAME_FD
        );
        break;
      case Constants.TAX_EXEMPTION_CODE_BETE_SC:
        set(line as InvoiceLine, Ubl.vatPercentage, 0);
        set(
          line as InvoiceLine,
          Ubl.taxSchemeName,
          Constants.TAX_SCHEME_NAME_SC
        );
        break;
      case Constants.TAX_EXEMPTION_CODE_BETE_00_44:
        set(line as InvoiceLine, Ubl.vatPercentage, 0);
        set(
          line as InvoiceLine,
          Ubl.taxSchemeName,
          Constants.TAX_SCHEME_NAME_00_44
        );
        break;
      case Constants.TAX_EXEMPTION_CODE_BETE_03_SE:
        set(line as InvoiceLine, Ubl.vatPercentage, 0);
        set(
          line as InvoiceLine,
          Ubl.taxSchemeName,
          Constants.TAX_SCHEME_NAME_03_SE
        );
        break;
      case Constants.TAX_EXEMPTION_CODE_BETE_MA:
        set(line as InvoiceLine, Ubl.vatPercentage, 0);
        set(
          line as InvoiceLine,
          Ubl.taxSchemeName,
          Constants.TAX_SCHEME_NAME_MA
        );
        break;
      case Constants.TAX_EXEMPTION_CODE_BETE_44:
        set(line as InvoiceLine, Ubl.vatPercentage, 0);
        set(
          line as InvoiceLine,
          Ubl.taxSchemeName,
          Constants.TAX_SCHEME_NAME_44
        );
        break;
      case Constants.TAX_EXEMPTION_CODE_BETE_45:
        set(line as InvoiceLine, Ubl.vatPercentage, 0);
        set(
          line as InvoiceLine,
          Ubl.taxSchemeName,
          Constants.TAX_SCHEME_NAME_45
        );
        break;
      case Constants.TAX_EXEMPTION_CODE_BETE_46_GO:
        set(line as InvoiceLine, Ubl.vatPercentage, 0);
        set(
          line as InvoiceLine,
          Ubl.taxSchemeName,
          Constants.TAX_SCHEME_NAME_46_GO
        );
        break;
      case Constants.TAX_EXEMPTION_CODE_BETE_46_TR:
        set(line as InvoiceLine, Ubl.vatPercentage, 0);
        set(
          line as InvoiceLine,
          Ubl.taxSchemeName,
          Constants.TAX_SCHEME_NAME_46_TR
        );
        break;
      case Constants.TAX_EXEMPTION_CODE_BETE_47_AS:
        set(line as InvoiceLine, Ubl.vatPercentage, 0);
        set(
          line as InvoiceLine,
          Ubl.taxSchemeName,
          Constants.TAX_SCHEME_NAME_47_AS
        );
        break;
      case Constants.TAX_EXEMPTION_CODE_BETE_47_DI:
        set(line as InvoiceLine, Ubl.vatPercentage, 0);
        set(
          line as InvoiceLine,
          Ubl.taxSchemeName,
          Constants.TAX_SCHEME_NAME_47_DI
        );
        break;
      case Constants.TAX_EXEMPTION_CODE_BETE_47_SE:
        set(line as InvoiceLine, Ubl.vatPercentage, 0);
        set(
          line as InvoiceLine,
          Ubl.taxSchemeName,
          Constants.TAX_SCHEME_NAME_47_SE
        );
        break;
      case Constants.TAX_EXEMPTION_CODE_BETE_47_TO:
        set(line as InvoiceLine, Ubl.vatPercentage, 0);
        set(
          line as InvoiceLine,
          Ubl.taxSchemeName,
          Constants.TAX_SCHEME_NAME_47_TO
        );
        break;

      case Constants.TAX_EXEMPTION_CODE_BETE_47_EE:
        set(line as InvoiceLine, Ubl.vatPercentage, 0);
        set(
          line as InvoiceLine,
          Ubl.taxSchemeName,
          Constants.TAX_SCHEME_NAME_47_EE
        );
        break;
      case Constants.TAX_EXEMPTION_CODE_BETE_47_EI:
        set(line as InvoiceLine, Ubl.vatPercentage, 0);
        set(
          line as InvoiceLine,
          Ubl.taxSchemeName,
          Constants.TAX_SCHEME_NAME_47_EI
        );
        break;
      case Constants.TAX_EXEMPTION_CODE_BETE_47_EX:
        set(line as InvoiceLine, Ubl.vatPercentage, 0);
        set(
          line as InvoiceLine,
          Ubl.taxSchemeName,
          Constants.TAX_SCHEME_NAME_47_EX
        );
        break;
      case Constants.TAX_EXEMPTION_CODE_BETE_NS:
        set(line as InvoiceLine, Ubl.vatPercentage, 0);
        set(
          line as InvoiceLine,
          Ubl.taxSchemeName,
          Constants.TAX_SCHEME_NAME_NS
        );
        break;
      default:
        unset(line as InvoiceLine, Ubl.taxSchemeName);
    }
  }
  if (!documentFormData) {
    // Invoice up to 49 lines

    set(newData, 'lines', newLines);

    FormDataHelpers.recalculateTaxesAndPrices(newData);

    // In case of MonoVat, reset monoVat if TaxSummary has more than one lines
    FormDataHelpers.manageMonoVat(newData);

    // In case of Mono Tax Category, reset mono TaxCategory if TaxSummary has more than one lines
    FormDataHelpers.manageMonoTaxCategory(newData);

    form.batch(() => {
      const properties = Object.keys(newData).filter((t) => t !== 'id');
      properties.forEach((propName) =>
        form.change(propName, newData[propName])
      );
    });
  } else {
    // Invoice with more than 49 lines
    const documentData: P2pData = cloneDeep(documentFormData);
    const currencyID = DataHelpers.getCurrencyID(documentData);

    const calculatedLineValues = LineProcessor.calculateLineValues(line);

    LineProcessor.calculateTaxesAndPrices(
      line,
      calculatedLineValues,
      currencyID
    );

    form.batch(() => {
      const properties = {
        taxSchemeID: Ubl.taxSchemeID,
        taxSchemeName: Ubl.taxSchemeName,
        taxCategoryCode: Ubl.taxCategoryCode,
        taxExemptionReasonCode: Ubl.taxExemptionReasonCode,
        taxExemptionReason: Ubl.taxExemptionReason,
        vatPercentage: Ubl.vatPercentage,
      };
      Object.keys(properties).forEach((propName) => {
        // update a (same) placeholder for each updated values in order to trigger validation
        form.change('waste', get(line, properties[propName]));
      });
    });
  }
};

interface ApplyCatalogSelection extends OnChangeFunctionInfo {}
/**
 * Sets lines info according to catalog selection
 * @param params
 */
const applyCatalogSelection = async (
  params: OnChangeDoParamsInfo<ApplyCatalogSelection>
) => {
  const form = params.form;
  const documentForm = params.documentForm;
  const documentFormData: any = documentForm?.getState().values; // EditableDatagrid
  const catalogService: CatalogService = CatalogService.getInstance(
    params.dataProvider
  );

  let codeClient = Ubl.codeClient;
  let codeStandard = Ubl.codeStandard;
  let codeSupplier = Ubl.codeSupplier;
  let description = Ubl.description;
  let unitCode = Ubl.unitCodeInvoice;
  let buyerId: string = documentFormData
    ? get(documentFormData?.properties, Metadata.recipientId)
    : get(params.formData.properties, Metadata.recipientId);
  let supplierId: string = documentFormData
    ? get(documentFormData?.properties, Metadata.issuerId)
    : get(params.formData.properties, Metadata.issuerId);
  let itemPrice = Ubl.price;
  let vatPercentage = Ubl.vatPercentage;

  const documentTypeCode = documentFormData
    ? get(documentFormData?.properties, Metadata.documentTypeCode)
    : get(params.formData.properties, Metadata.documentTypeCode);

  switch (documentTypeCode) {
    case DocumentTypeCode.DESADV:
      codeClient = 'Item[0].BuyersItemIdentification[0].ID[0]._';
      codeStandard = 'Item[0].StandardItemIdentification[0].ID[0]._';
      codeSupplier = 'Item[0].SellersItemIdentification[0].ID[0]._';
      description = 'Item[0].Description[0]._';
      unitCode = 'DeliveredQuantity[0].unitCode';
      break;
    case DocumentTypeCode.ORDERS:
      codeClient = 'LineItem[0].Item[0].BuyersItemIdentification[0].ID[0]._';
      codeStandard =
        'LineItem[0].Item[0].StandardItemIdentification[0].ID[0]._';
      codeSupplier = 'LineItem[0].Item[0].SellersItemIdentification[0].ID[0]._';
      description = 'LineItem[0].Item[0].Description[0]._';
      unitCode = 'LineItem[0].Quantity[0].unitCode';
      itemPrice = 'LineItem[0].Price[0].PriceAmount[0]._';
      vatPercentage = 'LineItem[0].TaxTotal[0].TaxSubtotal[0].Percent[0]._';
      buyerId = documentFormData
        ? get(
            documentFormData?.ublProperties,
            'Order[0].AccountingCustomerParty[0].Party[0].PartyIdentification[0].ID[0]._'
          )
        : get(
            params.formData.ublProperties,
            'Order[0].AccountingCustomerParty[0].Party[0].PartyIdentification[0].ID[0]._'
          );
      supplierId = documentFormData
        ? get(
            documentFormData?.ublProperties,
            'Order[0].SellerSupplierParty[0].Party[0].PartyIdentification[0].ID[0]._'
          )
        : get(
            params.formData.ublProperties,
            'Order[0].SellerSupplierParty[0].Party[0].PartyIdentification[0].ID[0]._'
          );
      break;
    default:
      codeClient = Ubl.codeClient;
      codeStandard = Ubl.codeStandard;
      codeSupplier = Ubl.codeSupplier;
      description = Ubl.description;
      unitCode = Ubl.unitCodeInvoice;
      itemPrice = Ubl.price;
      vatPercentage = Ubl.vatPercentage;
      break;
  }

  const catalogLines: CatalogLine[] | undefined =
    await catalogService.getCatalogLines(buyerId, supplierId);

  let catalogLine: CatalogLine;
  let newValue: any = params.newValue;

  if (catalogLines) {
    // There is an active available catalog
    if (!documentFormData) {
      // < 50 lines
      const originalData: any = form.getState().values;
      const newData: P2pData = cloneDeep(originalData);
      let newLines = newData.lines;

      // Retrieves the emitter and the line index from the params.emitter
      const { emitter, lineIndex } = getEmitterInLinesTable(params.emitter);
      if (
        documentTypeCode === DocumentTypeCode.INVOIC &&
        LineProcessor.isLineSGR(newLines[lineIndex])
      ) {
        // SGR lines in invoices are not overriden by catalog actions.
        return;
      }
      if (emitter) {
        if (emitter === codeSupplier) {
          catalogLine = catalogLines.filter(
            (c) =>
              `${c.lineNumber}__${c.sellersItemIdentification}` === newValue
          )[0];
          if (catalogLine) {
            set(
              newLines[lineIndex],
              codeClient,
              catalogLine?.buyersItemIdentification || ''
            );
            set(
              newLines[lineIndex],
              codeStandard,
              catalogLine?.standardItemIdentification || ''
            );
            set(
              newLines[lineIndex],
              description,
              catalogLine?.description || undefined
            );

            if (catalogLine?.sellersItemIdentification !== undefined) {
              set(
                newLines[lineIndex],
                unitCode,
                catalogLine?.deliveryUnit || undefined
              );
              if (
                documentTypeCode === DocumentTypeCode.INVOIC ||
                documentTypeCode === DocumentTypeCode.ORDERS
              ) {
                const price = catalogLine?.itemPrice
                  ? parseFloat(catalogLine?.itemPrice)
                  : undefined;
                set(newLines[lineIndex], itemPrice, price);

                set(newLines[lineIndex], vatPercentage, catalogLine?.vatNumber);
              }
              if (documentTypeCode === DocumentTypeCode.INVOIC) {
                const greenTax = catalogLine?.greenTax
                  ? parseFloat(catalogLine?.greenTax)
                  : undefined;

                set(newLines[lineIndex], Ubl.greenTaxPerUnitAmount, greenTax);
              }
            }
            newValue = catalogLine
              ? catalogLine?.sellersItemIdentification
              : newValue;
          }
        } else if (emitter === codeStandard) {
          catalogLine = catalogLines.filter(
            (c) =>
              `${c.lineNumber}__${c.standardItemIdentification}` === newValue
          )[0];
          if (catalogLine) {
            set(
              newLines[lineIndex],
              codeSupplier,
              catalogLine?.sellersItemIdentification || ''
            );
            set(
              newLines[lineIndex],
              codeClient,
              catalogLine?.buyersItemIdentification || ''
            );
            set(
              newLines[lineIndex],
              description,
              catalogLine?.description || undefined
            );

            if (catalogLine?.standardItemIdentification !== undefined) {
              set(
                newLines[lineIndex],
                unitCode,
                catalogLine?.deliveryUnit || undefined
              );
              if (
                documentTypeCode === DocumentTypeCode.INVOIC ||
                documentTypeCode === DocumentTypeCode.ORDERS
              ) {
                const price = catalogLine?.itemPrice
                  ? parseFloat(catalogLine?.itemPrice)
                  : undefined;
                set(newLines[lineIndex], itemPrice, price);

                set(newLines[lineIndex], vatPercentage, catalogLine?.vatNumber);
              }
              if (documentTypeCode === DocumentTypeCode.INVOIC) {
                const greenTax = catalogLine?.greenTax
                  ? parseFloat(catalogLine?.greenTax)
                  : undefined;

                set(newLines[lineIndex], Ubl.greenTaxPerUnitAmount, greenTax);
              }
            }
            newValue = catalogLine?.standardItemIdentification;
          }
        } else {
          // product description
          catalogLine = catalogLines.filter(
            (c) => `${c.lineNumber}__${c.description}` === newValue
          )[0];
          if (catalogLine) {
            set(
              newLines[lineIndex],
              codeClient,
              catalogLine?.buyersItemIdentification || ''
            );
            set(
              newLines[lineIndex],
              codeSupplier,
              catalogLine?.sellersItemIdentification || ''
            );
            set(
              newLines[lineIndex],
              codeStandard,
              catalogLine?.standardItemIdentification || ''
            );

            if (catalogLine?.description !== undefined) {
              set(
                newLines[lineIndex],
                unitCode,
                catalogLine?.deliveryUnit || undefined
              );
              if (
                documentTypeCode === DocumentTypeCode.INVOIC ||
                documentTypeCode === DocumentTypeCode.ORDERS
              ) {
                const price = catalogLine?.itemPrice
                  ? parseFloat(catalogLine?.itemPrice)
                  : undefined;
                set(newLines[lineIndex], itemPrice, price);

                set(newLines[lineIndex], vatPercentage, catalogLine?.vatNumber);
              }
              if (documentTypeCode === DocumentTypeCode.INVOIC) {
                const greenTax = catalogLine?.greenTax
                  ? parseFloat(catalogLine?.greenTax)
                  : undefined;

                set(newLines[lineIndex], Ubl.greenTaxPerUnitAmount, greenTax);
              }
            }
            newValue = catalogLine?.description;
          }
        }
        set(newLines[lineIndex], emitter, newValue);

        if (documentTypeCode === DocumentTypeCode.INVOIC) {
          FormDataHelpers.recalculateTaxesAndPrices(newData);

          FormDataHelpers.manageMonoVat(newData);
          FormDataHelpers.manageMonoTaxCategory(newData);
        }

        form.batch(() => {
          const properties = Object.keys(newData).filter((t) => t !== 'id');
          properties.forEach((propName) =>
            form.change(propName, newData[propName])
          );
        });
      }
    } else {
      // EditableDatagrid use case
      const lineData: any = form?.getState().values;
      if (
        documentTypeCode === DocumentTypeCode.INVOIC &&
        LineProcessor.isLineSGR(lineData)
      ) {
        // SGR lines in invoices are not overriden by catalog actions.
        return;
      }
      if (params.emitter === codeSupplier) {
        catalogLine = catalogLines.filter(
          (c) => `${c.lineNumber}__${c.sellersItemIdentification}` === newValue
        )[0];
        if (catalogLine) {
          set(
            lineData,
            codeClient,
            catalogLine?.buyersItemIdentification || ''
          );
          set(
            lineData,
            codeStandard,
            catalogLine?.standardItemIdentification || ''
          );
          set(lineData, description, catalogLine?.description || undefined);

          if (catalogLine?.sellersItemIdentification !== undefined) {
            set(lineData, unitCode, catalogLine?.deliveryUnit || undefined);
            if (
              documentTypeCode === DocumentTypeCode.INVOIC ||
              documentTypeCode === DocumentTypeCode.ORDERS
            ) {
              const price = catalogLine?.itemPrice
                ? parseFloat(catalogLine?.itemPrice)
                : undefined;
              set(lineData, itemPrice, price);
              set(lineData, vatPercentage, catalogLine?.vatNumber);
            }
            if (documentTypeCode === DocumentTypeCode.INVOIC) {
              const greenTax = catalogLine?.greenTax
                ? parseFloat(catalogLine?.greenTax)
                : undefined;
              set(lineData, Ubl.greenTaxPerUnitAmount, greenTax);
            }
          }
          newValue = catalogLine?.sellersItemIdentification;
        }
      } else if (params.emitter === codeStandard) {
        catalogLine = catalogLines.filter(
          (c) => `${c.lineNumber}__${c.standardItemIdentification}` === newValue
        )[0];
        if (catalogLine) {
          set(
            lineData,
            codeSupplier,
            catalogLine?.sellersItemIdentification || ''
          );
          set(
            lineData,
            codeClient,
            catalogLine?.buyersItemIdentification || ''
          );
          set(lineData, description, catalogLine?.description || undefined);

          if (catalogLine?.standardItemIdentification !== undefined) {
            set(lineData, unitCode, catalogLine?.deliveryUnit || undefined);
            if (
              documentTypeCode === DocumentTypeCode.INVOIC ||
              documentTypeCode === DocumentTypeCode.ORDERS
            ) {
              const price = catalogLine?.itemPrice
                ? parseFloat(catalogLine?.itemPrice)
                : undefined;
              set(lineData, itemPrice, price);
              set(lineData, vatPercentage, catalogLine?.vatNumber);
            }
            if (documentTypeCode === DocumentTypeCode.INVOIC) {
              const greenTax = catalogLine?.greenTax
                ? parseFloat(catalogLine?.greenTax)
                : undefined;
              set(lineData, Ubl.greenTaxPerUnitAmount, greenTax);
            }
          }
          newValue = catalogLine?.standardItemIdentification;
        }
      } else {
        // product description
        catalogLine = catalogLines.filter(
          (c) => `${c.lineNumber}__${c.description}` === newValue
        )[0];
        if (catalogLine) {
          set(
            lineData,
            codeClient,
            catalogLine?.buyersItemIdentification || ''
          );
          set(
            lineData,
            codeSupplier,
            catalogLine?.sellersItemIdentification || ''
          );
          set(
            lineData,
            codeStandard,
            catalogLine?.standardItemIdentification || ''
          );
          if (catalogLine?.description !== undefined) {
            set(lineData, unitCode, catalogLine?.deliveryUnit || undefined);
            if (
              documentTypeCode === DocumentTypeCode.INVOIC ||
              documentTypeCode === DocumentTypeCode.ORDERS
            ) {
              const price = catalogLine?.itemPrice
                ? parseFloat(catalogLine?.itemPrice)
                : undefined;
              set(lineData, itemPrice, price);
              set(lineData, vatPercentage, catalogLine?.vatNumber);
            }
            if (documentTypeCode === DocumentTypeCode.INVOIC) {
              const greenTax = catalogLine?.greenTax
                ? parseFloat(catalogLine?.greenTax)
                : undefined;
              set(lineData, Ubl.greenTaxPerUnitAmount, greenTax);
            }
          }
          newValue = catalogLine?.description;
        }
      }
      set(lineData, params.emitter || '', newValue);

      if (documentTypeCode === DocumentTypeCode.INVOIC) {
        const documentData: P2pData = cloneDeep(documentFormData);
        const currencyID = DataHelpers.getCurrencyID(documentData);
        const calculatedLineValues =
          LineProcessor.calculateLineValues(lineData);

        LineProcessor.calculateTaxesAndPrices(
          lineData,
          calculatedLineValues,
          currencyID
        );
      }

      form.batch(() => {
        const properties = {
          codeClient: codeClient,
          codeStandard: codeStandard,
          codeSupplier: codeSupplier,
          description: description,
          unitCode: unitCode,
          itemPrice: itemPrice,
          vatPercentage: vatPercentage,
        };
        Object.keys(properties).forEach((propName) => {
          // update a (same) placeholder for each updated values in order to trigger validation
          form.change('waste', get(lineData, properties[propName]));
        });
      });
    }
  }
};

interface ApplyExchangeRate extends OnChangeFunctionInfo {
  targetCurrency: string;
  exchangeRate: string;
}

/**
 * Computes new prices according to exchangeRate
 * @param params
 */
const applyExchangeRate = async (
  params: OnChangeDoParamsInfo<ApplyExchangeRate>
) => {
  const form = params.form;
  const originalData: any = form.getState().values;
  const newData: P2pData = cloneDeep(originalData);
  const schema: Template = params.template;

  // Retrieves the fields value
  const newExchangeRateFieldSource: string = getFieldSourceFromTemplate(
    schema,
    params.onChangeDoInfo.exchangeRate
  );
  const newExchangeRate: number = get(newData, newExchangeRateFieldSource);
  const targetCurrencyFieldSource: string = getFieldSourceFromTemplate(
    schema,
    params.onChangeDoInfo.targetCurrency
  );
  const targetCurrency: string = get(newData, targetCurrencyFieldSource);
  if (targetCurrency === undefined || newExchangeRate === undefined) return;

  // Sets the new price and new GT if any
  let newLines = newData.lines;
  newLines.forEach((line, index) => {
    const oldPrice = get(newLines[index], Ubl.price);
    if (oldPrice) {
      set(newLines[index], Ubl.price, round(oldPrice * newExchangeRate));
    }
    const oldGreenTax = get(newLines[index], Ubl.greenTaxPerUnitAmount);
    if (oldGreenTax) {
      set(
        newLines[index],
        Ubl.greenTaxPerUnitAmount,
        round(oldGreenTax * newExchangeRate)
      );
    }
  });
  DataHelpers.setCurrencyID(newData, targetCurrency);
  set(newData, 'lines', newLines);

  // Computes new amounts and sets the currency Ids
  FormDataHelpers.recalculateTaxesAndPrices(newData);

  form.batch(() => {
    const properties = Object.keys(newData).filter((t) => t !== 'id');
    properties.forEach((propName) => form.change(propName, newData[propName]));
  });
};

interface CustomSetFieldsRequired extends OnChangeFunctionInfo {
  targets: string[];
}

/**
 * Set Field required or not on condition (einfra) ...
 * @param params
 */
const customSetFieldsRequired = async (
  params: OnChangeDoParamsInfo<CustomSetFieldsRequired>
) => {
  let newSchema: Template = params.template;
  const form = params.form;
  const data = form.getState().values;

  // Check if one field has a value
  const valueExists = params.onChangeDoInfo.targets.some((target) => {
    const fieldSource = getFieldSourceFromTemplate(newSchema, target);
    const value = get(data, fieldSource);
    return value !== null && value !== undefined;
  });
  // Depending on valueExists, all fields are required or not
  params.onChangeDoInfo.targets.forEach((target) => {
    newSchema = setFieldProperty(newSchema, target, 'required', valueExists);
  });
  if (params.template.model !== newSchema.model) {
    params.updateFormTemplate(newSchema);
    params.onChangeDoInfo.targets.forEach((target) => {
      const fieldSource = getFieldSourceFromTemplate(newSchema, target);
      let value = get(data, fieldSource);
      resetField(form.change, fieldSource, value);
    });
  }
};

/* #region Receipt Advices */

/**
 * Get the line index for the emmiter field / or undefined if not in a line
 */
const parseLineIndex = (emmiterField: string | undefined) => {
  const tokens = emmiterField?.match(/lines\[([0-9])\]/);
  let idx = -1;
  if (tokens && tokens?.length > 1) {
    idx = +tokens[1];
  }

  return idx;
};

/**
 * Handle supplier change
 */
const changeSupplierReceiptAdvice = async (params: OnChangeDoParamsInfo) => {
  const form = params.form;
  const supplierId = params.newValue;

  if (typeof supplierId === 'string') {
    const recadvService = DocumentServiceFactory.create(
      ReceiptAdviceServiceCreator,
      params.dataProvider
    ) as ReceiptAdviceService;

    const newSellerSupplierParty = await recadvService.getSellerSupplierParty(
      supplierId
    );

    form.batch(() => {
      form.change('ublProperties.ReceiptAdvice[0].SellerSupplierParty', [
        newSellerSupplierParty,
      ]);
    });
  }
};

const setRecAdvAdditionalItemProperties = (
  form: FormApi,
  lineIdx: number,
  unitPrice: number | undefined,
  totalWithoutVat: number | undefined,
  totalVat: number | undefined,
  currencyID: string | number | null | undefined
) => {
  const buildQuantity = (amount: number | undefined) => {
    const quantity: Partial<QuantityType>[] = [];
    const item: Partial<QuantityType> = {};
    if (amount !== undefined) {
      item._ = amount;
    }

    if (typeof currencyID === 'string') {
      item.unitCode = currencyID;
    }
    quantity.push(item);

    return quantity;
  };

  //unit price - needed to set currency
  form.change(
    `lines[${lineIdx}].Item[0].AdditionalItemProperty[0].ValueQuantity`,
    buildQuantity(unitPrice)
  );

  //totalWithoutVat
  form.change(
    `lines[${lineIdx}].Item[0].AdditionalItemProperty[1].ValueQuantity`,
    buildQuantity(totalWithoutVat)
  );

  //totalVat
  form.change(
    `lines[${lineIdx}].Item[0].AdditionalItemProperty[2].ValueQuantity`,
    buildQuantity(totalVat)
  );
};

/**
 * If optional parameters are not set then the values will be read from the line 'lineIdx'
 */
const recalculateRecAdvLineValues = (
  form: FormApi,
  lineIdx: number,
  quantityOverwrite?: string | number | null | undefined,
  unitPriceOverwrite?: string | number | null | undefined,
  vatRateOverwrite?: string | number | null | undefined,
  currencyIDOverwrite?: string | number | null | undefined
) => {
  // current data
  const data = form.getState().values;

  const getValueOrDefault = (value: any, defaultValue: any) => {
    if (value !== undefined && value !== null) {
      return value;
    }
    return defaultValue;
  };

  const currencyID = getValueOrDefault(
    currencyIDOverwrite,
    get(
      data,
      `lines[${lineIdx}].Item[0].AdditionalItemProperty[0].ValueQuantity[0].unitCode`
    )
  );

  const quantity = getValueOrDefault(
    quantityOverwrite,
    get(data, `lines[${lineIdx}].ReceivedQuantity[0]._`)
  );

  const unitPrice = getValueOrDefault(
    unitPriceOverwrite,
    get(
      data,
      `lines[${lineIdx}].Item[0].AdditionalItemProperty[0].ValueQuantity[0]._`
    )
  );

  const vatRate = getValueOrDefault(
    vatRateOverwrite,
    get(data, `lines[${lineIdx}].Item[0].ClassifiedTaxCategory[0].Percent[0]._`)
  );

  const calculatedValues = TaxAndPriceUtils.computePrice(
    unitPrice,
    quantity,
    vatRate,
    undefined // TODO Check that ASAP
  );

  setRecAdvAdditionalItemProperties(
    form,
    lineIdx,
    unitPrice,
    round(calculatedValues.totalWithoutVat, 2),
    round(calculatedValues.totalVat, 2),
    currencyID
  );
};

/**
 * Handle price change and display the totals in a line. Also handles currency.
 */
const calculateLineReceiptAdvice = async (
  params: OnChangeDoParamsInfo<any>
) => {
  if (!params.emitter) {
    return;
  }

  const form = params.form;
  // current data
  const data = form.getState().values;

  // get line index
  let lineIdx = parseLineIndex(params.emitter);

  // get pricing information
  const currencyID = get(data, ReceiptAdviceService.MONO_CURRENCY_FIELD);

  form.batch(() => {
    recalculateRecAdvLineValues(
      form,
      lineIdx,
      undefined,
      undefined,
      undefined,
      currencyID
    );

    // If the VAT was changed on one of the lines - re-evaluate mono vat value
    if (
      params.emitter?.endsWith('Item[0].ClassifiedTaxCategory[0].Percent[0]._')
    ) {
      const percentArray = data.lines?.map(
        (l) => l.Item?.[0].ClassifiedTaxCategory?.[0].Percent?.[0]._
      );
      const samePercent = new Set(percentArray).size === 1;
      if (!samePercent) {
        form.change(ReceiptAdviceService.MONO_VAT_FIELD, undefined);
      } else {
        form.change(ReceiptAdviceService.MONO_VAT_FIELD, percentArray[0]);
      }
    }
  });
};

/**
 * Applies the same currency to all the lines in the receipt advice
 */
const applyMonoCurrencyReceiptAdvice = async (
  params: OnChangeDoParamsInfo<any>
) => {
  const form = params.form;
  const data = form.getState().values;

  const lines = data.lines as ReceiptLineDetails[];
  const currencyID = params.newValue;

  form.batch(() => {
    for (let i = 0; i < lines.length; i++) {
      const unitPrice = get(
        data,
        `lines[${i}].Item[0].AdditionalItemProperty[0].ValueQuantity[0]._`
      );

      const totalWithoutVat = get(
        data,
        `lines[${i}].Item[0].AdditionalItemProperty[1].ValueQuantity[0]._`
      );

      const totalVat = get(
        data,
        `lines[${i}].Item[0].AdditionalItemProperty[2].ValueQuantity[0]._`
      );

      const vatRate = get(
        data,
        `lines[${i}].Item[0].ClassifiedTaxCategory[0].Percent[0]._`
      );

      setRecAdvAdditionalItemProperties(
        form,
        i,
        unitPrice,
        totalWithoutVat,
        totalVat,
        currencyID
      );

      const quantity = get(data, `lines[${i}].ReceivedQuantity[0]._`);
      recalculateRecAdvLineValues(
        form,
        i,
        quantity,
        unitPrice,
        vatRate,
        currencyID
      );
    }
  });
};

const applyMonoVatReceiptAdvice = async (params: OnChangeDoParamsInfo<any>) => {
  const form = params.form;
  const data = form.getState().values;

  const lines = data.lines as ReceiptLineDetails[];

  form.batch(() => {
    for (let i = 0; i < lines.length; i++) {
      form.change(`lines[${i}].Item[0].ClassifiedTaxCategory[0].Percent`, [
        { _: params.newValue },
      ]);

      recalculateRecAdvLineValues(
        form,
        i,
        undefined,
        undefined,
        params.newValue,
        undefined
      );
    }
  });
};
/* #endregion*/

/**
 * Handle supplier change
 */
const changeSupplierOrder = async (params: OnChangeDoParamsInfo) => {
  const form = params.form;
  const supplierId = params.newValue;

  if (typeof supplierId === 'string') {
    const orderService = DocumentServiceFactory.create(
      OrderServiceCreator,
      params.dataProvider
    ) as OrderService;

    const newSellerSupplierParty = await orderService.getSellerSupplierParty(
      supplierId
    );

    form.batch(() => {
      form.change('ublProperties.Order[0].SellerSupplierParty', [
        newSellerSupplierParty,
      ]);
    });
  }
};

interface ApplyMonoCurrencyOrder extends OnChangeFunctionInfo {
  source?: string;
}

/**
 * Sets lines VAT percentage values according to a global selection
 * @param params
 */
const applyMonoCurrencyOrder = async (
  params: OnChangeDoParamsInfo<ApplyMonoCurrencyOrder>
) => {
  const form = params.form;
  const originalData: any = form.getState().values;
  const newData: P2pData = cloneDeep(originalData);

  FormDataHelpers.recalculateTaxesAndPrices(newData);

  set(newData, OrderService.MONO_CURRENCY_FIELD, params.newValue);

  form.batch(() => {
    const properties = Object.keys(newData).filter((t) => t !== 'id');
    properties.forEach((propName) => form.change(propName, newData[propName]));
  });
};

interface MangageSGR extends OnChangeFunctionInfo {}

/**
 * Sets data according to SGR specs
 * @param params
 */
const manageSGR = async (params: OnChangeDoParamsInfo<MangageSGR>) => {
  const form = params.form;
  const originalData: any = form.getState().values;
  const documentForm = params.documentForm;
  const documentFormData: any = documentForm?.getState().values; // EditableDatagrid
  const unitCodeField = getFieldFromSourceInTemplate(
    params.template,
    Ubl.unitCodeInvoice
  );
  let buyerId: string = documentFormData
    ? get(documentFormData?.properties, Metadata.recipientId)
    : get(params.formData.properties, Metadata.recipientId);

  if (!documentFormData) {
    // <= 50 lines
    const newData: P2pData = cloneDeep(originalData);
    let newLines = newData.lines;
    const currencyID = DataHelpers.getCurrencyID(newData);
    const { emitter, lineIndex } = getEmitterInLinesTable(params.emitter);
    newLines.forEach((line, index) => {
      if (lineIndex === index) {
        if (emitter === FieldTypes.SGR_LINE) {
          // Allowance Charge
          LineProcessor.ensureAllowanceChargeAndDiscount(line, currencyID);
          set(
            newLines[index],
            Ubl.unitCodeInvoice,
            unitCodeField ? unitCodeField.defaultValue : undefined
          );
          if (params.newValue) {
            // Line becomes a SGR one
            set(newLines[index], Ubl.taxSchemeID, '7');
            set(newLines[index], Ubl.taxSchemeName, 'E');
            set(newLines[index], Ubl.taxCategoryCode, 'VAT');
            set(
              newLines[index],
              Ubl.taxExemptionReasonCode,
              Constants.TAX_EXEMPTION_CODE_VATEX_EU_O
            );
            set(newLines[index], Ubl.taxExemptionReason, Constants.SGR_REASON);
            set(newLines[index], Ubl.vatPercentage, 0);
            if ('RO14399840' === buyerId || 'RO44231872' === buyerId) {
              set(newLines[index], Ubl.codeStandard, LineProcessor.SGR_EAN); // set as a default
            }
            set(newLines[index], Ubl.price, 0);
            set(newLines[index], Ubl.sgrTaxPerUnitAmount, 0.5);

            // taxTotal[0].taxSubtota[0] ... taxAmount
            set(newLines[index], Ubl.totalVat, 0);
            set(newLines[index], Ubl.totalVatCurrencyID, currencyID);
            set(newLines[index], Ubl.taxTotalVat, 0);
            set(newLines[index], Ubl.taxTotalVatCurrencyID, currencyID);
            // Other allowances => TODO manage source according to templates
            const taxes = get(newLines[index], FieldTypes.Taxes);
            if (taxes?.length > 0) {
              taxes?.forEach((t) => {
                if (t) {
                  t.value = undefined;
                }
              });
              set(newLines[index], FieldTypes.Taxes, taxes);
            }
          } else {
            // line is no more a SGR one
            set(newLines[index], Ubl.taxSchemeID, '7');
            const taxCategoryTopItem = get(newData, FieldTypes.TAX_CATEGORY);
            if (taxCategoryTopItem) {
              set(newLines[index], Ubl.taxSchemeName, taxCategoryTopItem);
            } else {
              set(newLines[index], Ubl.taxSchemeName, 'S');
            }
            set(newLines[index], Ubl.taxCategoryCode, 'VAT');
            delete newLines[index].taxTotal[0].taxSubtotal[0].taxCategory
              .taxExemptionReasonCode;
            delete newLines[index].taxTotal[0].taxSubtotal[0].taxCategory
              .taxExemptionReason;
            set(newLines[index], Ubl.codeStandard, undefined);
            set(newLines[index], Ubl.vatPercentage, undefined);
            set(newLines[index], Ubl.codeClient, undefined);
            set(newLines[index], Ubl.price, undefined);
            set(newLines[index], Ubl.sgrTaxPerUnitAmount, undefined);
            set(newLines[index], Ubl.sgrTaxAmount, undefined);
            set(newLines[index], Ubl.totalVat, undefined);
            set(newLines[index], Ubl.totalVatCurrencyID, undefined);
            set(newLines[index], Ubl.taxTotalVat, undefined);
            set(newLines[index], Ubl.taxTotalVatCurrencyID, undefined);
          }
          set(newLines[index], FieldTypes.SGR_LINE, params.newValue);
        }
      }
    });
    set(newData, 'lines', newLines);
    FormDataHelpers.recalculateTaxesAndPrices(newData);

    form.batch(() => {
      const properties = Object.keys(newData).filter((t) => t !== 'id');
      properties.forEach((propName) =>
        form.change(propName, newData[propName])
      );
    });
  } else {
    // EditableDatagrid use case > 50 lines
    const lineData: any = form?.getState().values;
    const documentData: P2pData = cloneDeep(documentFormData);
    const currencyID = DataHelpers.getCurrencyID(documentData);

    if (params.emitter === FieldTypes.SGR_LINE) {
      // Allowance Charge
      LineProcessor.ensureAllowanceChargeAndDiscount(lineData, currencyID);
      set(
        lineData,
        Ubl.unitCodeInvoice,
        unitCodeField ? unitCodeField.defaultValue : undefined
      );
      if (params.newValue) {
        // Line becomes a SGR one
        set(lineData, Ubl.taxSchemeID, '7');
        set(lineData, Ubl.taxSchemeName, 'E');
        set(lineData, Ubl.taxCategoryCode, 'VAT');
        set(
          lineData,
          Ubl.taxExemptionReasonCode,
          Constants.TAX_EXEMPTION_CODE_VATEX_EU_O
        );
        set(lineData, Ubl.taxExemptionReason, Constants.SGR_REASON);
        set(lineData, Ubl.vatPercentage, 0);
        if ('RO14399840' === buyerId || 'RO44231872' === buyerId) {
          set(lineData, Ubl.codeStandard, LineProcessor.SGR_EAN); // set as a default
        }
        set(lineData, Ubl.price, 0);
        set(lineData, Ubl.sgrTaxPerUnitAmount, 0.5);

        // taxTotal[0].taxSubtota[0] ... taxAmount
        set(lineData, Ubl.totalVat, 0);
        set(lineData, Ubl.totalVatCurrencyID, currencyID);
        set(lineData, Ubl.taxTotalVat, 0);
        set(lineData, Ubl.taxTotalVatCurrencyID, currencyID);
      } else {
        // line is no more a SGR one
        set(lineData, Ubl.taxSchemeID, '7');
        const taxCategoryTopItem = get(documentData, FieldTypes.TAX_CATEGORY);
        if (taxCategoryTopItem) {
          set(lineData, Ubl.taxSchemeName, taxCategoryTopItem);
        } else {
          set(lineData, Ubl.taxSchemeName, 'S');
        }
        set(lineData, Ubl.taxCategoryCode, 'VAT');
        delete lineData.taxTotal[0].taxSubtotal[0].taxCategory
          .taxExemptionReasonCode;
        delete lineData.taxTotal[0].taxSubtotal[0].taxCategory
          .taxExemptionReason;
        set(lineData, Ubl.codeStandard, undefined);
        set(lineData, Ubl.vatPercentage, undefined);
        set(lineData, Ubl.codeClient, undefined);
        set(lineData, Ubl.price, undefined);
        set(lineData, Ubl.sgrTaxPerUnitAmount, undefined);
        set(lineData, Ubl.sgrTaxAmount, undefined);
        set(lineData, Ubl.totalVat, undefined);
        set(lineData, Ubl.totalVatCurrencyID, undefined);
        set(lineData, Ubl.taxTotalVat, undefined);
        set(lineData, Ubl.taxTotalVatCurrencyID, undefined);
      }
      set(lineData, FieldTypes.SGR_LINE, params.newValue);
    }
    const calculatedLineValues = LineProcessor.calculateLineValues(lineData);

    LineProcessor.calculateTaxesAndPrices(
      lineData,
      calculatedLineValues,
      currencyID
    );

    form.batch(() => {
      const properties = {
        codeClient: Ubl.codeClient,
        additionalItemCode: Ubl.additionalItemCode,
        codeStandard: Ubl.codeStandard,
        taxSchemeID: Ubl.taxSchemeID,
        taxSchemeName: Ubl.taxSchemeName,
        taxCategoryCode: Ubl.taxCategoryCode,
        taxExemptionReasonCode: Ubl.taxExemptionReasonCode,
        taxExemptionReason: Ubl.taxExemptionReason,
        vatPercentage: Ubl.vatPercentage,
        itemPrice: Ubl.price,
        unitCodeInvoice: Ubl.unitCodeInvoice,
        sgrTaxPerUnitAmount: Ubl.sgrTaxPerUnitAmount,
        totalVat: Ubl.totalVat,
        totalVatCurrencyID: Ubl.totalVatCurrencyID,
        taxTotalVat: Ubl.taxTotalVat,
        taxTotalVatCurrencyID: Ubl.taxTotalVatCurrencyID,
        allowanceCharge: Ubl.allowanceCharge,
        sgrCheck: FieldTypes.SGR_LINE,
      };
      Object.keys(properties).forEach((propName) => {
        // update a (same) placeholder for each updated values in order to trigger validation
        form.change('waste', get(lineData, properties[propName]));
      });
    });
  }
};

/**
 * Sets CustomizationID, ProfileID values and vat, Tax category,
 * Tax exemption code selection choices and fields properties changes (if any)
 * according to the AP capabilities vs country
 * to be able to propose the info relative to standard or non standard PEPPOL.
 * Until now we have two types of AP managed :
 *  - belgium (BE) : UBL.BE
 *  - the rest => peppol.eu
 */
const setPeppolAPSpecifcs = (
  form: FormApi,
  newTemplate: Template,
  newData: any,
  newCountry: string,
  params:
    | OnChangeDoParamsInfo<ChangePeppolCustomerName>
    | OnChangeDoParamsInfo<ManagePeppolCountrySpecifics>,
  capabilities: PeppolProfileDocument[]
) => {
  const peppolService = new PeppolService();
  const customizationID = peppolService.getCustomizationID(
    newCountry,
    capabilities
  );
  let searchKey = 'EU';
  if (customizationID === PeppolService.PEPPOL_CUSTOMIZATION_ID_BE) {
    searchKey = 'BE';
  }

  if (newCountry !== 'BE') {
    // Any country but Belgium =>
    // customaizationID is EU and let's
    // try to find in template if there is
    // some country specific.
    searchKey = newCountry;
  }
  // target fields (same for all entries, initilalization with default one)
  const taxCategoryField = params.onChangeDoInfo.taxCategories.find(
    (f) => f.countryCode === 'EU'
  )?.field;
  const taxExemptionReasonCodeField =
    params.onChangeDoInfo.taxExemptionReasonCodes.find(
      (f) => f.countryCode === 'EU'
    )?.field;
  const vat = params.onChangeDoInfo.vat.find(
    (f) => f.countryCode === 'EU'
  )?.field;

  const MonoVATFieldName = getFieldFromSourceInTemplate(
    newTemplate,
    FieldTypes.MONO_VAT
  )?.name;

  const fieldsToUpdate: any[] = [
    taxCategoryField,
    taxExemptionReasonCodeField,
    taxExemptionReasonCodeField,
    vat,
  ];

  if (
    DataHelpers.regulatorExtraDetails(newData) ===
    RegulatorExtraDetailsType.PEPPOL
  ) {
    DataHelpers.setCustomizationID(newData, customizationID);

    let taxCategoriesInfo = params.onChangeDoInfo.taxCategories.find(
      (f) => f.countryCode === searchKey
    );
    if (!taxCategoriesInfo) {
      taxCategoriesInfo = params.onChangeDoInfo.taxCategories.find(
        (f) => f.countryCode === 'EU'
      );
    }
    newTemplate = setFieldProperty(
      newTemplate,
      taxCategoryField,
      'selectValues',
      taxCategoriesInfo?.selectValues
    );
    newTemplate = setFieldProperty(
      newTemplate,
      taxCategoryField,
      'defaultValue',
      taxCategoriesInfo?.defaultValue
    );

    let taxExemptionReasonCodesInfo =
      params.onChangeDoInfo.taxExemptionReasonCodes.find(
        (f) => f.countryCode === searchKey
      );
    if (!taxExemptionReasonCodesInfo) {
      taxExemptionReasonCodesInfo =
        params.onChangeDoInfo.taxExemptionReasonCodes.find(
          (f) => f.countryCode === 'EU'
        );
    }
    newTemplate = setFieldProperty(
      newTemplate,
      taxExemptionReasonCodeField,
      'selectValues',
      taxExemptionReasonCodesInfo?.selectValues
    );
    newTemplate = setFieldProperty(
      newTemplate,
      taxExemptionReasonCodeField,
      'defaultValue',
      taxExemptionReasonCodesInfo?.defaultValue
    );

    let vatInfo = params.onChangeDoInfo.vat.find(
      (f) => f.countryCode === searchKey
    );
    if (!vatInfo) {
      vatInfo = params.onChangeDoInfo.vat.find((f) => f.countryCode === 'EU');
    }
    newTemplate = setFieldProperty(
      newTemplate,
      vat,
      'selectValues',
      vatInfo?.selectValues
    );
    newTemplate = setFieldProperty(
      newTemplate,
      vat,
      'defaultValue',
      vatInfo?.defaultValue
    );

    // Sets MONO VAT choices with same as the line VAT field
    if (MonoVATFieldName) {
      const vatInfo = getFieldFromTemplate(newTemplate, vat || '');
      newTemplate = setFieldProperty(
        newTemplate,
        MonoVATFieldName,
        'selectValues',
        vatInfo?.selectValues
      );
      set(newData, FieldTypes.MONO_VAT, vatInfo?.defaultValue);
    }

    DataHelpers.setProfileID(newData, PeppolService.PEPPOL_PROFILE_ID);

    // Manages Fields properties changes
    let fieldsPropertiesChangesInfo: FieldPropertiesChanges | undefined =
      params.onChangeDoInfo.fieldsPropertiesChanges?.find(
        (f) => f.countryCode === searchKey
      );
    if (fieldsPropertiesChangesInfo === undefined) {
      fieldsPropertiesChangesInfo =
        params.onChangeDoInfo.fieldsPropertiesChanges?.find(
          (f) => f.countryCode === 'EU'
        );
    }
    if (fieldsPropertiesChangesInfo !== undefined) {
      fieldsPropertiesChangesInfo.fieldsChanges.forEach((f) => {
        f.properties.forEach((p) => {
          newTemplate = setFieldProperty(newTemplate, f.name, p.name, p.value);
        });
      });
    }

    // Update the new template and the targeted fields
    if (params.template.model !== newTemplate.model) {
      params.updateFormTemplate(newTemplate);
      newData.lines.forEach((l, index) => {
        fieldsToUpdate.forEach((target) => {
          const fieldSource = `lines[${index}].${getFieldSourceFromTemplate(
            newTemplate,
            target
          )}`;
          const field = getFieldFromTemplate(newTemplate, target);
          set(newData, fieldSource, field?.defaultValue);
        });
      });
    }
    FormDataHelpers.recalculateTaxesAndPrices(newData);

    form.batch(() => {
      const properties = Object.keys(newData).filter((t) => t !== 'id');
      properties.forEach((propName) =>
        form.change(propName, newData[propName])
      );
    });
  }
};

interface CountrySpecifics {
  countryCode: string;
  field: string;
  defaultValue?: string;
  selectValues: any[];
}

interface PropertyChange {
  name: string;
  value: any;
}
interface FieldsChanges {
  name: string;
  properties: PropertyChange[];
}
interface FieldPropertiesChanges {
  countryCode: string;
  fieldsChanges: FieldsChanges[];
}
export interface ManagePeppolCountrySpecifics extends OnChangeFunctionInfo {
  taxCategories: CountrySpecifics[];
  taxExemptionReasonCodes: CountrySpecifics[];
  vat: CountrySpecifics[];
  fieldsPropertiesChanges?: FieldPropertiesChanges[];
}

/**
 * Sets CustomizationID, ProfileID values and vat, Tax category,
 * Tax exemption code selection choices according to the country
 * to be able to propose the info relative to standard or non standard PEPPOL.
 * Until now we have two types of country managed :
 *  - belgium (BE)
 *  - the rest of the country => EU
 * @param params
 */
const managePeppolCountrySpecifics = async (
  params: OnChangeDoParamsInfo<ManagePeppolCountrySpecifics>
) => {
  const form = params.form;
  const originalData: any = form.getState().values;
  const newData: P2pData = cloneDeep(originalData);
  let newTemplate: Template = params.template;

  const peppolService = new PeppolService();

  const capabilities: PeppolProfileDocument[] =
    await peppolService.getEndpointCapabilities(
      get(newData, FieldTypes.PEPPOL_ACCESS_POINT),
      'Invoice'
    );
  setPeppolAPSpecifcs(
    form,
    newTemplate,
    newData,
    `${params.newValue}`,
    params,
    capabilities
  );
};

export interface FetchBuyerDetails extends OnChangeFunctionInfo {
  recipientId: string;
  extraFieldsToReset?: string[];
}

export const fetchBuyerDetails = async (
  params: OnChangeDoParamsInfo<FetchBuyerDetails>
) => {
  const form = params.form;
  const newData: P2pData = cloneDeep(params.formData);
  const recipientIdSource = getFieldSourceFromTemplate(
    params.template,
    params.onChangeDoInfo.recipientId
  );
  const companyID =
    params.newValue !== undefined
      ? params.newValue
      : get(newData, recipientIdSource);
  if (!companyID) return;

  const buyerCustomerGLNField = getFieldFromSourceInTemplate(
    params.template,
    Ubl.buyerCustomerGLN
  );

  const companyService = new CompanyService(params.dataProvider);
  // fetch DB
  const detailsInfo = await companyService.getDetails(companyID);

  // Reset previous values entered by user if declared in template
  if (params.onChangeDoInfo.extraFieldsToReset?.length) {
    params.onChangeDoInfo.extraFieldsToReset?.forEach((f) => {
      const fieldSourceToReset = getFieldSourceFromTemplate(params.template, f);
      if (fieldSourceToReset) {
        resetField(form.change, fieldSourceToReset, '');
      }
    });
  }

  if (detailsInfo) {
    // Priority to DB
    // Sets the info
    resetField(
      form.change,
      Ubl.accountingCustomerCountryCode,
      detailsInfo.billingAddress?.idCountry &&
        detailsInfo.billingAddress?.idCountry?.length > 0
        ? detailsInfo.billingAddress?.idCountry
        : 'RO'
    ); // Hack. Nedeed for reset validation
    set(
      newData,
      Ubl.accountingCustomerCountryCode,
      detailsInfo.billingAddress?.idCountry &&
        detailsInfo.billingAddress?.idCountry?.length > 0
        ? detailsInfo.billingAddress?.idCountry
        : 'RO'
    );
    set(newData, 'properties.' + Metadata.recipientId, companyID);
    set(newData, 'properties.' + Metadata.recipientName, detailsInfo?.name);
    set(
      newData,
      Ubl.accountingCustomerCityName,
      detailsInfo.billingAddress?.city
    );

    // HOT FIX : TO BE REVISITED ONCE WE HAVE ALL THE SPECS CLEARED (same as InvoiceService.fillIssuerInformation())
    // V2 DB Address workaround which is take into account on backend side could not fit issuer info.
    // B/E sets name = tb_address.street, street = tb_address.additionalstreet
    let street = detailsInfo.billingAddress?.street;
    if (!street || street === '') {
      // Meaning that additionalstreet was empty or not defined
      // => reverts the B/E V2 DB workaround
      street = detailsInfo.billingAddress?.name;
    }

    set(newData, Ubl.accountingCustomerStreetName, street);
    set(
      newData,
      Ubl.accountingCustomerBuildingNumber,
      detailsInfo.billingAddress?.buildingNumber
    );
    set(
      // Postal Code
      newData,
      Ubl.accountingCustomerPostalZone,
      detailsInfo.billingAddress?.postalCode
    );
    set(
      newData,
      Ubl.accountingCustomerCompanyID,
      detailsInfo.registrationNumber
    );

    set(newData, Ubl.accountingCustomerGLN, detailsInfo.billingAddress?.gln);

    if (buyerCustomerGLNField) {
      set(newData, Ubl.buyerCustomerGLN, detailsInfo.billingAddress?.gln);
    }

    set(
      // IBAN
      newData,
      Ubl.payerIban,
      detailsInfo.billingAddress?.financialAccount
    );

    set(
      // BANK name
      newData,
      Ubl.payerBank,
      detailsInfo.billingAddress?.bank
    );
  } else {
    // fetch ANAF
    const companyInfo: any = await companyService.fetchCompanyDetails(
      companyID
    );
    if (companyInfo) {
      // ANAF fetch
      set(
        newData,
        Ubl.accountingCustomerCountryCode,
        companyInfo.address?.country && companyInfo.address?.country?.length > 0
          ? companyInfo.address?.country
          : 'RO'
      );
      set(newData, 'properties.' + Metadata.recipientId, companyID);
      set(
        newData,
        'properties.' + Metadata.recipientName,
        companyInfo.account?.name
      );
      set(newData, Ubl.accountingCustomerCityName, companyInfo.address?.city);
      set(
        newData,
        Ubl.accountingCustomerStreetName,
        companyInfo.address?.street
      );
      set(
        newData,
        Ubl.accountingCustomerBuildingNumber,
        companyInfo.address?.buildingnumber
      );
      set(
        newData,
        Ubl.accountingCustomerCompanyID,
        companyInfo.account?.registryNumber
      );

      // PostalCode or PostalZone seems to not exists in companyInfo
      set(newData, Ubl.accountingCustomerPostalZone, '');

      set(newData, Ubl.accountingCustomerGLN, companyInfo.address?.gln);

      if (buyerCustomerGLNField) {
        set(newData, Ubl.buyerCustomerGLN, companyInfo.address?.gln);
      }

      set(
        // IBAN
        newData,
        Ubl.payerIban,
        companyInfo.address?.iban
      );

      set(
        // BANK name
        newData,
        Ubl.payerBank,
        companyInfo.address?.bank
      );
    } else {
      // Either DB and ANAF doesn't have data.
      // Removes inconsistent values.
      // Neded to be done as finally action in order to not be in
      // conflict with any previous setting (form.batch vs form.change).
      resetField(form.change, Ubl.accountingCustomerCountryCode, '');
      set(
        newData,
        'properties.' + Metadata.recipientName,
        params.translate('dxMessages.error_messages.company_info_not_found')
      );
      resetField(form.change, Ubl.accountingCustomerCityName, '');
      resetField(form.change, Ubl.accountingCustomerStreetName, '');
      resetField(form.change, Ubl.accountingCustomerBuildingNumber, '');
      resetField(form.change, Ubl.accountingCustomerCompanyID, '');
      resetField(form.change, Ubl.accountingCustomerCountryCode, '');
      resetField(form.change, Ubl.accountingCustomerPostalZone, '');
      resetField(form.change, Ubl.accountingCustomerGLN, '');
      if (buyerCustomerGLNField) {
        resetField(form.change, Ubl.buyerCustomerGLN, '');
      }
      resetField(form.change, Ubl.payerIban, '');
      resetField(form.change, Ubl.payerBank, '');
    }
  }
  form.batch(() => {
    const properties = Object.keys(newData).filter((t) => t !== 'id');
    properties.forEach((propName) => form.change(propName, newData[propName]));
  });
};

// Object which helps to match a JSON definition of an onChange function
// with the corresponding real function.
const availableOnChangeFunctions = {
  changeCustomer,
  getDespatchAdviceTemplate,
  getInvoiceTemplate,
  calculateTaxesAndTotals,
  changeInvoiceReference,
  setDespatchAdviceReference,
  changeLocation,
  dispatchValue,
  hideUnhideFields,
  computeTransportCost,
  descriptionTypeManagement,
  setFieldsRequired,
  applyMonoVat,
  applyTaxCategory,
  applyLineTaxCategory,
  applyLineTaxExemptionReasonCode,
  applyExchangeRate,
  changeDeliveryLocation,
  changeShipmentLocation,
  applyCatalogSelection,
  customSetFieldsRequired,
  changePeppolCustomerName,

  changeSupplierReceiptAdvice,
  calculateLineReceiptAdvice,
  applyMonoCurrencyReceiptAdvice,
  applyMonoVatReceiptAdvice,

  changeSupplierOrder,
  applyMonoCurrencyOrder,

  manageSGR,
  managePeppolCountrySpecifics,

  fetchBuyerDetails,
};

export const onChangeFunctions = {
  availableOnChangeFunctions,
  callOnChangeDo: (templateOnChangeDo: string, params: OnChangeDoParams) => {
    const onChangeDoInfo = getOnChangeFunctionFromTemplate(
      params.template.onChangeFunctions,
      templateOnChangeDo
    );
    const newParams: OnChangeDoParamsInfo = {
      ...params,
      onChangeDoInfo,
    };

    if (!availableOnChangeFunctions.hasOwnProperty(onChangeDoInfo.onChangeDo)) {
      throw new Error(
        `Change function name '${onChangeDoInfo.onChangeDo}' is not known.`
      );
    }

    availableOnChangeFunctions[onChangeDoInfo.onChangeDo](newParams);
  },
};
